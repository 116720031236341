import React from 'react';
import clsx from 'clsx';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { alpha, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  actionsRoot: {
    transition: 'all 0.3s ease',
    transform: 'translateX(-110%)',
    opacity: 0,
    visibility: 'hidden',
    marginLeft: 10,
  },
  hoverAction: {
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {

      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  winner: {
    color: "green"
  }
}));


const TableItem = ({ teamSeasonsStats, opponentTeamStats }) => {
  const classes = useStyles();

  return (
      <>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div className={teamSeasonsStats?.ptsPerGame > opponentTeamStats?.ptsPerGame && classes.winner}>{Number(teamSeasonsStats?.ptsPerGame).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div className={teamSeasonsStats?.rebPerGame > opponentTeamStats?.rebPerGame && classes.winner}>{Number(teamSeasonsStats?.rebPerGame).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div className={teamSeasonsStats?.astPerGame > opponentTeamStats?.astPerGame && classes.winner}>{Number(teamSeasonsStats?.astPerGame).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div className={teamSeasonsStats?.stlPerGame > opponentTeamStats?.stlPerGame && classes.winner}>{Number(teamSeasonsStats?.stlPerGame).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div className={teamSeasonsStats?.tovPerGame < opponentTeamStats?.tovPerGame && classes.winner}>{Number(teamSeasonsStats?.tovPerGame).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div className={teamSeasonsStats?.fgPct > opponentTeamStats?.fgPct && classes.winner}>{Number(teamSeasonsStats?.fgPct).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div  className={teamSeasonsStats?.fg3PtPct > opponentTeamStats?.fg3PtPct && classes.winner}>{Number(teamSeasonsStats?.fg3PtPct).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div  className={teamSeasonsStats?.ftPct > opponentTeamStats?.ftPct && classes.winner}>{Number(teamSeasonsStats?.ftPct).toFixed(1)}</div>
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <div  className={teamSeasonsStats?.blkPerGame > opponentTeamStats?.blkPerGame && classes.winner}>{Number(teamSeasonsStats?.blkPerGame).toFixed(1)}</div>
          </TableCell>
        </TableRow>
      </>
  );
};

export default TableItem;
