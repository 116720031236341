import React from "react"
import BreadcrumbShower from "../../components/Breadcrumbs/Breadcrumbs";
import * as ROUTES from "../../route/routes";
import {useLocation} from "react-router-dom";


export default function Layout({children}) {
    const location = useLocation();

    return(
        <>
            {
                location.pathname !== ROUTES.HOME &&  <BreadcrumbShower />
            }
            {children}
        </>
    )
}