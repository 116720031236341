import React, {createContext, useContext, useEffect, useState} from "react";

import {authRoutes, routes} from "./routeConfigs";

import {Router, Route, useHistory} from "react-router-dom";
import AuthLayout from "../pages/Auth/AuthLayout";
import Layout from "../pages/Dashboard/Layout";
import NotFound from "../pages/NotFound/NotFound";
import * as ROUTES from "./routes";
import {fetchUserDetails, resetState} from "../vendor/redux/actions/authActions";
import {useDispatch, useSelector} from "react-redux";
import PageLoader from "../components/PageComponents/PageLoader";
import ConfirmDialog from "../components/Common/ConfirmDialog";

export const CommonComponentsContext = createContext("")

export const Routing = () => {
    const { isLoggedIn } = useSelector((state) => state.signInReducer);
    const dispatch = useDispatch();
    const history = useHistory();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [modalOptions, setModalOptions] = useState({});

    const init = () => {
        if (isLoggedIn) {
            dispatch(fetchUserDetails(history));
        }
    };

    useEffect(() => {
       init();
        resetState();
    }, [isLoggedIn])


    return (
        <>
            <CommonComponentsContext.Provider value={{setDialogOpen, setModalOptions}}>
                 <ConfirmDialog
                     open={dialogOpen}
                     onClose={() => setDialogOpen(false)}
                     onConfirm={modalOptions.onConfirm}
                     title={modalOptions.title}
                     content={modalOptions.content}
                 />
                 <PageLoader open={false} />

                 {authRoutes.map((item) => {
                     return (
                         <Route
                             key={item.path}
                             exact={item.exact}
                             path={item.path}
                         >
                             <AuthLayout variant='default'>
                                 {item.component}
                             </AuthLayout>
                         </Route>
                     );
                 })}
                 {routes.map((item) => {
                     return (
                         <Route
                             key={item.path}
                             exact={item.exact}
                             path={item.path}>
                             <Layout variant='default'>
                                 {item.component}
                             </Layout>
                         </Route>
                     );
                 })}
                 <Route path={ROUTES.NOT_FOUND} component={NotFound} />
            </CommonComponentsContext.Provider>
        </>
    );
};