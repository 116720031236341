import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


import makeStyles from '@material-ui/core/styles/makeStyles';

import AppContext from '../contextProvider/AppContextProvider/AppContext';
import globalStyles from '../../assets/theme/GlobalCss';
import { LAYOUT_TYPES } from '../../constants/ThemeOptions';

import HorizontalDark from './HorizontalLayouts/HorizontalDark';

const useStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AppLayout = ({ children }) => {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  const  layout  = LAYOUT_TYPES.HORIZONTAL_DARK;
  // const { loadUser } = useSelector(({ auth }) => auth);
  // const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  // globalStyles();

  // useEffect(() => {
  //   dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
  //   setLayoutLoader(false);
  //
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //
  // if (showLayoutLoader || !loadUser) {
  //   return (
  //     <div className={classes.circularProgressRoot}>
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  // if (['/signin', '/signup', '/forgot-password'].includes(location.pathname)) {
  //   return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
  // }

  switch (layout) {
    case LAYOUT_TYPES.HORIZONTAL_DARK: {
      return <HorizontalDark children={children}/>;
    }
  }
};

export default AppLayout;
