import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { alpha, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  hoverAction: {
    '&:hover $actionsRoot': {
      transform: 'translateX(0%)',
      opacity: 1,
      visibility: 'visible',
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {

      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
}));

let values = [
  'point_avg', 'assist_avg', 'rebound_avg',  'turnover_avg', 'steal_avg', 'block_avg'
]

const TableItem = ({ row }) => {
  const classes = useStyles()
  return (
      <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
        <TableCell className={classes.tableCellRoot}>
          <div>{Boolean(row) ? `${row?.player__first_name} ${row?.player__last_name} - ${Number(row?.value).toFixed(1)}`: "-"} </div>
        </TableCell>
      </TableRow>
  );
};

export default TableItem;
