import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCEED,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCEED,
    RESET_STATE,
    SET_LOGIN,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_SUCCEED,
    SET_USER_FAILURE,
    SET_USER_REQUEST,
    SET_USER_SUCCEED,
    SIGN_UP_FAILURE,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCEED,
    VERIFY_ACCOUNT_FAILURE,
    VERIFY_ACCOUNT_REQUEST,
    VERIFY_ACCOUNT_SUCCEED,
} from '../consts'
import User from "../../../services/api/user";
import * as ROUTES from "../../../route/routes";
import {clearStorage, getRefreshToken, setToken} from "../../helpers/storage";

export function SignUpUser(type, values) {
    let response;
    return async (dispatch) => {
        dispatch({type: SIGN_UP_REQUEST});
        try {
            switch (type) {
                case 'email':
                    response = await User.signUp(values);
                    break
                case 'google':
                    response = await User.signUpGoogle({auth_token: values});
                    if(response.status === 200) {
                        await setToken(response.data.token.access, response.data.token.refresh);
                        dispatch({
                            type: SET_USER_SUCCEED,
                            payload: response.data.user,
                        });
                    }
                    break
                case 'facebook':
                    response = await User.signUpFacebook({auth_token: values});
                    if(response.status === 200) {
                        await setToken(response.data.token.access, response.data.token.refresh);
                        dispatch({
                            type: SET_USER_SUCCEED,
                            payload: response.data.user,
                        });
                    }
                    break
            }

            if(response.status === 200) {
                dispatch({
                        type: SIGN_UP_SUCCEED,
                        payload: response,
                    }
                );
            } else {
                dispatch({
                    type: SIGN_UP_FAILURE,
                    payload: response
                })
            }
        } catch (error) {
            dispatch({
                type: SIGN_UP_FAILURE,
                payload: response
            })
        }
    };
}

export function verifyAccount(data) {
    return async (dispatch) => {
        dispatch({type: VERIFY_ACCOUNT_REQUEST});
        try {
            const response = await User.verifyAccount(data);
            if (response.status === 200) {
                dispatch({
                    type: VERIFY_ACCOUNT_SUCCEED,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: VERIFY_ACCOUNT_FAILURE
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function setPassword(values) {
    return async (dispatch) => {
        dispatch({type: SET_PASSWORD_REQUEST});
        try {
            const response = await User.setPassword(values);
            if (response.status === 200) {
                setToken(response.data.token.access, response.data.token.refresh);
                dispatch({
                    type: SET_PASSWORD_SUCCEED,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function changePassword(values) {
    return async (dispatch) => {
        dispatch({type: CHANGE_PASSWORD_REQUEST});
        try {
            const response = await User.changePassword(values);
            if (response.status === 200) {
                dispatch({
                    type: CHANGE_PASSWORD_SUCCEED,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function signInUser (values) {
    return async (dispatch) => {
        dispatch({ type: SET_USER_REQUEST });
        const result = await User.signIn(values);
        if (result?.status === 200) {
            setToken(result.data.token.access, result.data.token.refresh);
            dispatch({
                type: SET_USER_SUCCEED,
                payload: result.data.user,
            });
        }
        else {
            dispatch({
                type: SET_USER_FAILURE,

            });
        }
    };
}

export function fetchUserDetails(history) {
    return async (dispatch) => {
        dispatch({type: SET_USER_REQUEST});
        try {
            const result = await User.getUserDetails();
            if (result?.status === 200) {
                return dispatch({
                    type: SET_USER_SUCCEED,
                    payload: result.data,
                });
            } else {
                // let refreshToken =  getRefreshToken()
                // const result = await User.refreshToken({refresh: refreshToken})
                // if(result.status === 200) {
                //     await setToken(result.data.access, result.data.refresh);
                //     dispatch({
                //         type: SET_USER_SUCCEED,
                //         payload: result.data.user,
                //     });
                // }
                clearStorage()
                history.push(ROUTES.SIGN_IN)
            }
        } catch (error) {
            return error
        }
    };
}

export function forgotPassword(values) {
    return async (dispatch) => {
        dispatch({ type: FORGOT_PASSWORD_REQUEST });
        const result = await User.forgotPassword(values);
        if (result?.status === 200) {
            dispatch({
                type: FORGOT_PASSWORD_SUCCEED,
                payload: result,
            });
        } else {
            dispatch({
                type: FORGOT_PASSWORD_FAILURE,
                payload:  result
            });
        }
    };
}

export function signOut (values, history) {
    return async (dispatch) => {
        try {
            const result = await User.signOut(values)
            if (result.status === 205) {
                await clearStorage();
                dispatch({
                    type: SET_LOGIN,
                    isLoggedIn: false,
                    status: null
                });
                history.push(ROUTES.SIGN_IN)
            }
        }   catch (error) {
            return error;
        }
    };
}

export function resetState() {
    return async (dispatch) => {
        dispatch({
            type: RESET_STATE,
        });
    }
}