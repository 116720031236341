import React, { useState } from 'react';
import clsx from 'clsx';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  actionsRoot: {
    transition: 'all 0.3s ease',
    transform: 'translateX(-110%)',
    opacity: 0,
    visibility: 'hidden',
    marginLeft: 10,
  },
  hoverAction: {
    '&:hover $actionsRoot': {
      transform: 'translateX(0%)',
      opacity: 1,
      visibility: 'visible',
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: '0 none',
    fontWeight: "800",
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {

      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const TableItem = ({ row }) => {
  const classes = useStyles();


  return (
    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
      <TableCell className={classes.tableCellRoot}>
        <div>{row.title}</div>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
