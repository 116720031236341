import React, {useEffect, useState} from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableHeading from './TableHeading';
import TableItem from './TableItem';

const LineUpsTable = ({ data, title, color }) => {
    const [leadersData, setLeadersData] = useState([])

    const findLeader = (data, title, value, point) => {
        let filteredData = data.find(item => item[title] === point);
        filteredData.leaderStats = [];
        let obj = {}
        obj.player__first_name = filteredData.player__first_name;
        obj.player__last_name = filteredData.player__last_name;
        obj.value = filteredData[value];
        setLeadersData(leadersData => [...leadersData, obj]);
    }

    const titles = {
        point_rank: 'point_avg',
        assist_rank: 'assist_avg',
        rebound_rank: 'rebound_avg',
        turnover_rank: 'turnover_avg',
        steal_rank: 'steal_avg',
        block_rank: 'block_avg',
    }

    useEffect(() => {
        for (const title in titles)
        {
            findLeader(data, title, [titles[title]], 1)
        }
    }, [])

    return (
        <div className="Cmt-table-responsive">
            <Table>
                <TableHead>
                    <TableHeading color={color}  title={title}/>
                </TableHead>
                <TableBody>
                    {leadersData.map((row, index) => (
                        <TableItem  key={index} row={row} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default LineUpsTable;
