import React from "react";
import PersonalInfo from "../../components/Profile/PersonalInfo";
import ChangePassword from "../../components/Profile/ChangePassword";
import SubscriptionsHistory from "../../components/Profile/SubscriptionHistory/SubscriptionHistory";
import MailingList from "../../components/Profile/MailingList";

const sideBarOptions = [
    {
        name: 'Personal information',
        type: 'item',
        tabName: "personalInfo",
        component: <PersonalInfo />,
    },
    {
        name: 'Security details',
        type: 'item',
        tabName: "security",
        component: <ChangePassword />,
    },
    {
        name: 'Subscription history',
        type: 'item',
        tabName: "subscription",
        component: <SubscriptionsHistory />,
    },
    {
        name: 'Mailing list subscriptions',
        type: 'item',
        tabName: "mailingSubscription",
        component: <MailingList />,
    },
];

export default sideBarOptions;

