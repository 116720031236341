import React, {useEffect} from 'react';
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import {Divider} from "material-ui-core";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import RecentGamesTable from "./RecentGameTable/RecentGameTeamData";
import {useSelector} from "react-redux";
import DataLoader from "../../../../components/PageComponents/DataLoader";

export default function RecentGamesData() {
    const {recentGame, recentGameStatus} = useSelector((state) => state.singleGameReducer);
    return(
        <>
            <CmtCard>
                <CmtCardHeader style={{textAlign: "center"}} subTitle={"Recent Game Analysis"} title="Recent Game" />
                <Divider/>
                <CmtCardContent>
                    {
                        recentGameStatus === 'pending' &&
                        <DataLoader />
                    }

                    {
                        recentGame && <RecentGamesTable/>
                    }

                    {
                        !recentGame && recentGameStatus === 'success' && <p style={{textAlign: 'center'}}>No data to show</p>
                    }


                </CmtCardContent>
            </CmtCard>
        </>
    )
}