import React, {useEffect} from 'react';
import {Box, TextField} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {NavLink, useHistory} from "react-router-dom";
import {CurrentAuthMethod} from "../../constants/AppConstants";
import * as ROUTES from "../../route/routes";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setPassword, verifyAccount} from "../../vendor/redux/actions/authActions";
import {useFormik} from "formik";
import {setPasswordSchema as validationSchema} from "../../utils/yup-schemas/authSchemas";
import showNotificationMessage from "../../utils/notifications";
import LoadingButton from "../../components/PageComponents/LoadingButton";

const useStyles = makeStyles(theme => ({
    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
    },
}));

export default function SetPassword({method = CurrentAuthMethod,  variant = 'default', wrapperVariant = 'default' })  {
    const classes = useStyles({ variant });
    const params = useParams();
    const uuid = params.uuid;
    const dispatch = useDispatch()
    const verifyState = useSelector((state) => state.verifyAccountReducer)
    const statusVerified = verifyState.status
    const dataVerified = verifyState.data
    const setPasswordState = useSelector((state) => state.setPasswordReducer)
    const setPasswordStatus = setPasswordState.status
    const history = useHistory();

    useEffect(() => {
        dispatch(verifyAccount(uuid));
        if(statusVerified === 'error') {
            showNotificationMessage(statusVerified, 'The verification code is wrong', "Verification failed")
        }
    }, [statusVerified]);


    useEffect(() => {
        if(setPasswordStatus === 'success') {
            history.push(ROUTES.HOME)
        }
    }, [setPasswordStatus]);

    const initialValues = {
        password: '',
        password_confirm: '',
        id: null
    }

    useEffect(() => {}, [dataVerified])

    const onSubmit = async (values) => {
        values.id = dataVerified?.id
        await dispatch(setPassword(values));
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
        isSubmitting,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return(
        <>
            <Typography component="div" variant="h1" className={classes.titleRoot}>
                Set password
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <TextField
                        label="Password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="password"
                        className={classes.textFieldRoot}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.password &&
                            Boolean(errors.password)
                        }
                        helperText={
                            errors.password
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Confirm password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="password"
                        className={classes.textFieldRoot}
                        name="password_confirm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.password_confirm &&
                            Boolean(errors.password_confirm)
                        }
                        helperText={
                            errors.password_confirm
                        }
                    />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                    <LoadingButton
                        loading={statusVerified === "pending" || setPasswordStatus === "pending"}
                        disabled={!isValid || isSubmitting || dataVerified === 'error'}
                        variant="contained"
                        color="primary"
                        titleText="Confirm"
                    />
                    <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                        <NavLink to={ROUTES.SIGN_IN}>
                            Sign in
                        </NavLink>
                    </Box>
                </Box>
            </form>
        </>
    )
}

