import {useHistory, matchPath, Link} from "react-router-dom";
import * as ROUTES from "../../route/routes";
import {Typography} from "@mui/material";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {Breadcrumbs} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        marginBottom: "25px",
        zIndex: 3,
        '& a': {
            textDecoration: "none"
        }
    },
    activeRoot: {
        color: "#fea621",
    },
    navRoot: {
        color: "#bfbdbd",
    },

}));

const BreadcrumbShower = () => {
    const classes = useStyles();
    const history = useHistory();
    const pathname = history.location.pathname;
    const routeKeys = Object.keys(ROUTES);

    const definePath = (path, pathName) => matchPath(pathName,  {
        path: path,
        exact: true,
        strict: true
    });

    const pathArray = pathname?.split("/");
    pathArray.shift();
    const currentRote = Object.values(ROUTES).find(route => definePath(route, pathname));
    const roteArray = currentRote?.split("/");
    roteArray.shift();
    const crumbChain = definePath("/", pathname)? [] : ["/"];
     pathArray.reduce((prev, curr, index) => {
             if(roteArray[index].includes(":") || !roteArray[index]) {crumbChain.pop()};
             crumbChain.push(`${prev}/${curr}`); return `${prev}/${curr}`
         }, "");

    const breadcrumbs = crumbChain.map((crumb, index) => {
        if (index !== crumbChain.length - 1) {
            return (
                <Link to={crumb} key={index}>
                    <Typography  className={classes.navRoot}>
                        {routeKeys.find(key => definePath(ROUTES[key], crumb))?.replaceAll("_", " ")}
                    </Typography>
                </Link>
            )
        }
        if (index === crumbChain.length - 1) {
            return (
                <Typography className={classes.activeRoot} key={index}>
                    {routeKeys.find(key => definePath(ROUTES[key], crumb))?.replaceAll("_", " ")}
                </Typography>)
        }
    });

    return(
        <Box className={classes.breadcrumbRoot}>
            <Breadcrumbs
                separator={<NavigateNextIcon className={classes.navRoot} fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Box>
    );
}

export default BreadcrumbShower;