import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import TableHeading from './TableHeading';
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    actionsRoot: {
        transition: 'all 0.3s ease',
        transform: 'translateX(-110%)',
        opacity: 0,
        visibility: 'hidden',
        marginLeft: 10,
    },
    hoverAction: {
        '&:hover $actionsRoot': {
            transform: 'translateX(0%)',
            opacity: 1,
            visibility: 'visible',
        },
    },
    tableRowRoot: {
        position: 'relative',
        transition: 'all .2s',
        borderTop: `solid 1px ${theme.palette.borderColor.main}`,
        '&:last-child': {
            borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
        },
    },
    tableCellRoot: {
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: "center",
        paddingTop: 5,
        display: "flex",
        justifyContent: "center",
        paddingBottom: 5,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.text.primary,
        borderBottom: '0 none',
        '&:first-child': {
            paddingLeft: 24,
        },
        '&:last-child': {

            paddingRight: 24,
        },
        '& .Cmt-avatar-more': {
            fontSize: 10,
            color: theme.palette.primary.main,
        },
    },
    tableCellSecondaryColor: {
        color: theme.palette.text.secondary,
    },
    blockRoot: {
        display: 'block',
        fontSize: 14,
    },
}));

const LineUpsTable = ({ moneyLine, pointSpread, pointSpreadDecimal, overLine, overUnder, overLineText, teamTitle}) => {
    const classes = useStyles();

  return (
    <div className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableHeading title={teamTitle} />
        </TableHead>
        <TableBody>
            <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                <TableCell className={classes.tableCellRoot}>
                    <>
                        <div style={{marginRight: "6px"}}>
                            {moneyLine ? moneyLine : '-'}
                        </div>
                    </>
                </TableCell>
            </TableRow>
            <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                <TableCell className={classes.tableCellRoot}>
                    <>
                        <div style={{marginRight: "6px"}}>
                            {pointSpread ? pointSpread : '-'} / {pointSpreadDecimal ? pointSpreadDecimal : '-'}
                        </div>
                    </>
                </TableCell>
            </TableRow>
            <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                <TableCell className={classes.tableCellRoot}>
                    <>
                        <div style={{marginRight: "6px"}}>
                            {overLineText} {overUnder ? overUnder : '-'} / {overLine ? overLine : "-"}
                        </div>
                    </>
                </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default LineUpsTable;
