import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import GameSingleHeader from './GameSingleHeader';
import {useDispatch, useSelector} from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Rosters from "./Rosters";
import GameCharts from "./GameCharts";
import RecentGames from "./RecentGames";
import HeadToHead from "./HeadToHead";
import {useParams} from "react-router-dom";
import {getGameDetails, getGamePlayers} from "../../../vendor/redux/actions/gamesActions";
import DataLoader from "../../../components/PageComponents/DataLoader";
import Grid from "@material-ui/core/Grid";
import GamblingCenter from "./GamblingCenter";

const useStyles = makeStyles(() => ({
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  loaderRoot: {
    display: "flex",
    height: "100%",
    alignSelf: "center",
    justifyContent: "center"
  },
}));

const GameSingle = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('rosters');
  const params = useParams();
  const gameId = params.gameId;
  const {gameDetails, gamePlayers, getGameStatus, getPlayerStatus} = useSelector((state) => state.singleGameReducer);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const init = async () => {
    await dispatch(getGameDetails(gameId))
    await dispatch(getGamePlayers(gameId))
  }

  useEffect(() => {
    if(!gameDetails || !gamePlayers) {
      init()
    }
  }, [])

  return (
      <Box height="100%">
        {(getGameStatus === "pending" || getPlayerStatus === "pending") &&
        <Grid className={classes.loaderRoot}>
          <DataLoader />
        </Grid>
        }
        {
          gameDetails && gamePlayers && <Box className={classes.pageFull}>
            <GameSingleHeader classes={classes} tabValue={tabValue} handleTabChange={handleTabChange} />
            <Box style={{maxWidth: "100%"}}>
              {
                tabValue === 'rosters' && <Rosters/>
              }
              {
                tabValue === 'gameCharts' && <GameCharts gameId={gameId}/>
              }
              {
                tabValue === 'recentGames' && <RecentGames gameId={gameId}/>
              }
              {
                tabValue === '' +
                'headToHead' && <HeadToHead gameId={gameId}/>
              }
              {
                tabValue === '' +
                'gamblingCentral' && <GamblingCenter gameId={gameId}/>
              }
            </Box>
          </Box>
        }
      </Box>
  );
};

export default GameSingle;
