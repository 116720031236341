import {
    GET_GAMBLING_FAILURE,
    GET_GAMBLING_REQUEST, GET_GAMBLING_SUCCEED,
    GET_GAME_CHART_REQUEST,
    GET_GAME_CHART_SUCCEED,
    GET_GAME_HEAD_TO_HEAD_REQUEST,
    GET_GAME_HEAD_TO_HEAD_SUCCEED,
    GET_GAME_PLAYERS_REQUEST,
    GET_GAME_PLAYERS_SUCCEED,
    GET_GAME_QUARTER_REQUEST,
    GET_GAME_QUARTER_SUCCEED, GET_LIVE_FAILURE, GET_LIVE_REQUEST, GET_LIVE_SUCCEED,
    GET_RECENT_GAME_REQUEST,
    GET_RECENT_GAME_SUCCEED,
    GET_SINGLE_GAME_REQUEST,
    GET_SINGLE_GAME_SUCCEED,
    GET_STATS_GAME_REQUEST,
    GET_STATS_GAME_SUCCEED,
    UPDATE_GAME_SUBSCRIPTION_SINGLE_REQUEST,
    UPDATE_GAME_SUBSCRIPTION_SINGLE_SUCCEED,
} from "../consts";
import {getDateMDY} from "../../../utils/dateHelper";

const initialState = {
    getPlayerStatus: null,
    getGameStatus: null,
    recentGameStatus: null,
    gameDetails: null,
    gamePlayers: null,
    recentGame: null,
    gameCharts: null,
    headToHeadData: null,
    gameStats: null,
    gameQuarter: null,
    gamblingData: null,
    gameLive: null
}

const singleGameReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_SINGLE_GAME_REQUEST:
            return {
                ...state,
                getGameStatus: 'pending',
                gameDetails: null,
            };
        case  GET_SINGLE_GAME_SUCCEED:
            return {
                ...state,
                getGameStatus: "success",
                gameDetails: action.payload
            };
        case GET_GAME_PLAYERS_REQUEST:
            return {
                ...state,
                getPlayerStatus: 'pending',
                gamePlayers: null,
            };
        case  GET_GAME_PLAYERS_SUCCEED:
            return {
                ...state,
                getPlayerStatus: "success",
                gamePlayers: action.payload
            };
        case GET_RECENT_GAME_REQUEST:
            return {
                ...state,
                recentGameStatus: 'pending',
            };
        case  GET_RECENT_GAME_SUCCEED:
            if(action.payload?.completed?.home_team && action.payload?.completed?.away_team) {
                action.payload?.completed?.home_team?.forEach(item => item.start_time = getDateMDY(new Date(item?.start_time)))
                action.payload?.completed?.away_team?.forEach(item => item.start_time = getDateMDY(new Date(item?.start_time)))
            }
            return {
                ...state,
                recentGameStatus: 'success',
                recentGame: action.payload
            };
        case GET_STATS_GAME_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_STATS_GAME_SUCCEED:
            return {
                ...state,
                status: "success",
                gameStats: action.payload
            };
        case GET_GAME_CHART_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_GAME_CHART_SUCCEED:
            return {
                ...state,
                status: "success",
                gameCharts: action.payload
            };
        case GET_GAME_QUARTER_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_GAME_QUARTER_SUCCEED:
            return {
                ...state,
                status: "success",
                gameQuarter: action.payload
            };
        case GET_GAME_HEAD_TO_HEAD_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_GAME_HEAD_TO_HEAD_SUCCEED:
            return {
                ...state,
                status: "success",
                headToHeadData: action.payload
            };
        case GET_GAMBLING_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_GAMBLING_SUCCEED:
            return {
                ...state,
                status: "success",
                gamblingData: action.payload
            };
        case GET_LIVE_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_LIVE_SUCCEED:
            return {
                ...state,
                status: "success",
                gameLiveData: action.payload
            };
        case  GET_LIVE_FAILURE:
            return {
                ...state,
                status: "error"
            };
        case UPDATE_GAME_SUBSCRIPTION_SINGLE_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  UPDATE_GAME_SUBSCRIPTION_SINGLE_SUCCEED:
            return {
                ...state,
                ...state.gameDetails.game.subscription_status = action.payload.status,
                status: "success",
            };

        default:
            return state;
    }
}

export default singleGameReducer;