import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
    paddingTop: "25px",
    paddingBottom: '15px'
  },
  title:{
    padding: '0px 16px 20px 16px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 10,
    letterSpacing: 1.5,
  },
}));

const CmtVertical = props => {
  const { menuItems, title, type } = props;
  const classes = useStyles();

  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {title && <div className={classes.title}>
        {title}
      </div>
      }
      {menuItems?.map((item, index) => {
        switch (type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem title={title} {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtVertical;
