import React, {useEffect, useState} from 'react'
import {Box, Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import GoogleLogin from "react-google-login";
import {makeStyles} from "@material-ui/core/styles";
import {SignUpUser} from "../../vendor/redux/actions/authActions";
import {useDispatch, useSelector} from "react-redux";
import showNotificationMessage from "../../utils/notifications";
import {useHistory} from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import {Facebook} from "@material-ui/icons";
import {SITE_KEY_FOR_FACEBOOK, SITE_KEY_FOR_GOOGLE} from "../../constants/keys";

const useStyles = makeStyles(theme => ({
    socialButtons: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            margin: 0,
        },
    },
    googleButton: {
        width: "100%",
        height: '48px',
        padding: "12px 8px !important",
        [theme.breakpoints.down("xs")]: {
            margin: 0,
        },

        '&  div': {
            padding: '4px !important',
            display: 'flex',
        },
    },

    facebookButton: {
        height: '48px',
        padding: "12px 8px",
        width: "100%",
        backgroundColor: '#1877f2',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        color: '#ffffff',
        border: 'none',
        cursor: 'pointer',
        fontWeight: '600',
        [theme.breakpoints.down("xs")]: {
            margin: 0,
            marginTop: 15,
        },

        '& svg' : {
            marginRight: '6px',
            fontSize: '30px'
        }
    }
}));

export const SocialSignUp = ({titleText}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {signUpStatus, message, title} = useSelector((state) => state.signUpReducer)
    const [authProvider, setAuthProvider] = useState();

    const onSubmit = async (type, value) => {
        setAuthProvider('email')
        await dispatch(SignUpUser(type, value));
    }

    const responseGoogle = async (response) => {
        if(response){
            setAuthProvider('google')
            await onSubmit('google', response?.tokenObj?.id_token)
        }
    }

    const responseFacebook = async (response) => {
        if(response) {
            setAuthProvider('facebook')
            await onSubmit('facebook', response.accessToken)
        }

    }

    useEffect(() => {

            switch (signUpStatus ) {
                case 'error':
                    showNotificationMessage(signUpStatus, message, title)
                    break;
            }

            if(authProvider === 'email' && signUpStatus === 'success') {
                showNotificationMessage(signUpStatus, 'You have successfully signed up', title)
            }
    }, [signUpStatus])

    return (
        <>
            <Box mt={10}>
                <Divider/>
                <Box mt={9}>
                    <Typography component="p">
                        OR {titleText} WITH
                    </Typography>
                </Box>
                <Box mt={9}>
                    <Box className={classes.socialButtons}>
                        <Box width="100%" mr={2}>
                            <GoogleLogin
                                clientId={SITE_KEY_FOR_GOOGLE}
                                buttonText="Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                theme={'dark'}
                                cookiePolicy={'single_host_origin'}
                                className={classes.googleButton}
                            />
                        </Box>
                        <Box width="100%" ml={2} ml={{xs: 0}}>
                            <FacebookLogin
                                appId={SITE_KEY_FOR_FACEBOOK}
                                icon={<Facebook />}
                                textButton={'Facebook'}
                                cssClass={classes.facebookButton}
                                callback={responseFacebook}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}