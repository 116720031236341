export default function errorMessages(type) {
    const errorObj = {
        validation: {
            required: 'This field is required.',
            email: 'Please provide a valid email address.',
            passwordMinimals: 'Password must be at least 6 characters, include one uppercase and two digits.',
            confirmPassword: 'Confirm password does not match to password.',
            age: 'You must be 18 or older.',
        },
    }
        return errorObj[type]
}