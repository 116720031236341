import React, {useEffect} from "react"
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RegularSeasonSeriesTable from "./HeadToHead/RegularSeasonSeriesTable";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import {Divider} from "material-ui-core";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import SeasonSeriesSummary from "./HeadToHead/SeasonSeriesSummary";
import {useDispatch, useSelector} from "react-redux";
import {getTeamsHeadToHead} from "../../../vendor/redux/actions/gamesActions";
import DataLoader from "../../../components/PageComponents/DataLoader";

const useStyles = makeStyles(() => ({
    profileMainContent: {
        '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
}));

export default function HeadToHead({gameId}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {headToHeadData, status} = useSelector((state) => state.singleGameReducer);
    const leadership = headToHeadData?.leaders

    const getData = async () => {
       await dispatch(getTeamsHeadToHead(gameId))
    }

    useEffect(() => {
        getData()
    }, [])

    return(
        <>
            <Grid item xs={12} className={classes.profileMainContent}>
                <Box mb={6}>
                    <CmtCard>
                        <CmtCardHeader style={{textAlign: "center"}} title="Regular Season Series" />
                        <Divider/>
                        <CmtCardContent>
                            {
                                headToHeadData &&  <RegularSeasonSeriesTable/>
                            }
                        </CmtCardContent>
                    </CmtCard>
                </Box>
                <Box mb={6}>
                    <CmtCard>
                        <CmtCardHeader style={{textAlign: "center"}} title="Regular Season Series Leaders" />
                        <Divider/>
                        <CmtCardContent>
                            {
                                leadership?.away_team?.length > 0 && leadership?.home_team?.length > 0  && <SeasonSeriesSummary/>
                            }

                            {
                                !leadership?.away_team?.length && !leadership?.home_team?.length && status !== 'pending' &&
                                <p style={{textAlign: "center"}}>
                                    No data to show
                                </p>
                            }
                            {
                                status === 'pending' && <DataLoader/>
                            }
                        </CmtCardContent>
                    </CmtCard>
                </Box>
            </Grid>
        </>
    )
}