export const HOME = '/';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const VERIFY_ACCOUNT = '/set-password/:uuid/';
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGOT_PASSWORD_VERIFICATION = '/forget-password-verification/:uuid/';

export const PRICING = '/pricing';
export const INSIGHTS = '/stats-and-insights';
export const INSIGHTS_NHL = '/stats-and-insights/nhl';
export const INSIGHTS_NFL = '/stats-and-insights/nfl';
export const INSIGHTS_MLB = '/stats-and-insights/MLB';
export const GAME_DETAILS = '/stats-and-insights/game-details/:gameId';
export const CONTACT_US = '/contact-us';
export const PROFILE = '/profile';
export const CHANGE_EMAIL_VERIFICATION = '/change-email-verification/:uuid/';
export const NOT_FOUND = '/404';
