import React, {createContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "material-ui-core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";

import SideBar from "../../components/AppLayout/partials/SideBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {alpha} from "@material-ui/core/styles";
import CmtImage from "../../@coremat/CmtImage";
import profileSidebarNavs from "./sideBarOptions";
import CmtAvatar from "../../@coremat/CmtAvatar";
import CmtCard from "../../@coremat/CmtCard";

const useStyles = makeStyles(theme => ({
    spacing: 1,
    headerRoot: {
        margin: '-30px -15px 0 -15px',
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 30,
        height : "205px",
        paddingBottom: 20,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 56,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: -50,
            marginRight: -50,
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: -65,
            marginRight: -65,
            paddingLeft: 65,
            paddingRight: 65,
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: -88,
            marginRight: -88,
            paddingLeft: 88,
            paddingRight: 88,
        },
    },
    headerBgImg: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '280px',
        zIndex: 0,
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: "#131238",

        },
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    headerContent: {
        position: 'relative',
        zIndex: 3,
        justify: "center",
    },
    titleRoot: {
        color: theme.palette.common.white,
        marginBottom: 4,
        fontSize: "22px",
        [theme.breakpoints.down("xs")] : {
            textAlign: "center",
        }
    },
    subTitleRoot: {
        color: alpha(theme.palette.common.white, 0.74),
    },
    followerList: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: -15,
        marginRight: -15,
        [theme.breakpoints.up('md')]: {
            marginLeft: -24,
            marginRight: -24,
        },
    },
    followerListItem: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'center',
        cursor: 'pointer',
        color: alpha(theme.palette.common.white, 0.74),
        fontSize: 12,
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
        '&:not(:first-child)': {
            borderLeft: `solid 1px ${alpha(theme.palette.common.white, 0.38)}`,
        },
    },
    followerListTitle: {
        color: theme.palette.common.white,
        marginBottom: 3,
    },
    tabItem: {
        maxWidth: 'none',
        textTransform: "uppercase",
        minWidth: 10,
        minHeight: 10,
        padding: '5px 10px',
        color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightRegular,
    },
    pageFull: {
        width: '100%',
    },
    title: {
        height: "100px",
    },
    profileSidebar: {
        '@media screen and (max-width: 1499px)': {
            flexBasis: '100%',
            maxWidth: '100%',
            paddingBottom: "15px"
        },
        '@media screen and (min-width: 1499px)': {
            flexBasis: '100%',
            width: '300px',
        },
    },
    profileMainContent: {
        '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
    optionStyles: {
        position: "relative",
        alignItems:"center",
        justifyContent:"center",
        padding: "30px",
    },
}));

export const SideBarContext = createContext("");

export default function Profile() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activeOption, setActiveOption] = useState(profileSidebarNavs[0].tabName);
    const {user} = useSelector(state => state.signInReducer);

    const activeOptionObject = profileSidebarNavs.find(option => option.tabName === activeOption);

    const handleOptionChange = (newOption) => {
        setActiveOption(newOption);
    };

    const handleSelectOption = async () => {
        if(activeOptionObject.action) {
            await dispatch(activeOptionObject.action());
        }
    };

    useEffect(handleSelectOption,[activeOption]);

    return(
        <React.Fragment>
            <Box className={classes.pageFull}>
                <Box className={classes.headerRoot}>
                <Box className={classes.headerBgImg}>
                    <CmtImage src={'/images/profile-bg-img.png'} />
                </Box>
                <Box className={classes.headerContent}>
                    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" mb={4}>
                        <Box className={classes.title} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
                            <Box mr={{ sm: 4, md: 5, lg: 6 }} mb={{ xs: 3, sm: 0 }}>
                                <CmtAvatar size={80} src={"profile_pic"} alt={user?.first_name} />
                            </Box>
                            <Box>
                                <Typography className={classes.titleRoot} component="div" variant="h1">
                                    {user?.first_name} {user?.last_name}
                                </Typography>
                                <Typography className={classes.subTitleRoot}>{user?.email}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                </Box>
                <Box>
                <Grid container style={{position: "relative", justifyContent: "space-between"}}>
                    <Grid item xs={12} lg={3} className={classes.profileSidebar}>
                        <SideBarContext.Provider value={{handleOptionChange, activeOption}}>
                            <Box className={classes.profileSidebar}>
                                <SideBar sidebarNavs={profileSidebarNavs}  type={"item"}/>
                            </Box>
                        </SideBarContext.Provider>
                    </Grid>
                    <Grid item xs={12} lg={8} className={classes.profileMainContent}>
                        <CmtCard>
                            <Box lg={{m: 12}}>
                                <Box className={classes.optionStyles}>
                                    <Typography variant="h4">
                                        {activeOptionObject.name}
                                    </Typography>
                                </Box>
                                <Divider />
                                <Grid container className={classes.optionStyles}>
                                     <Grid item xs={12}>
                                         {activeOptionObject.component}
                                     </Grid>
                                </Grid>
                            </Box>
                        </CmtCard>
                    </Grid>
                </Grid>
                </Box>
            </Box>
        </React.Fragment>
    );
}