import React from 'react'
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtCarousel from "../../../@coremat/CmtCarousel";
import BannerAndText from "../../../components/Common/BannerAndText";

const useStyles = makeStyles(theme => ({
    cardRoot: {
        padding: 16,
        marginBottom: 16
    },
    cardContent: {
        padding: 40
    },
    titleRoot: {
        fontSize: 10,
        letterSpacing: 1.5,
        marginBottom: 7,
        textTransform: 'uppercase',
    },
    subTitleRoot: {
        fontSize: 20,
        marginBottom: 10,
    },
}));


export default function BannerSlider() {
    return(
        <CmtCarousel
            outline={false}
            color="#676499"
            activeColor="#100E3A"
            dotSize={10}
            settings={settings}
            data={carouselImages}
            dotPosition="bottom"
            renderRow={RenderRow}
            mb={2}
        />

    )
}

export  function RenderRow (item, index) {
    const classes = useStyles();
    return (
        <Box key={index} className={classes.itemRoot}>
                <BannerAndText fontSize="subTitleLg" button={item.button} text={item.title}  bgImage={item.pic} />
        </Box>
    );
};

const settings = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "arrows": false,
    "initialSlide": 1,
    "swipeToSlide": true,
    "vertical": false,
    "verticalSwiping": false,
    "adaptiveHeight": true
};

const carouselImages = [
    {
        "title": "Get the best insights on sports games and matches prior to placing your bets.",
        "pic": '/images/get-started.jpg',
        button: true,
    },
    {
        "title": "We are working add to add features and awesome stats to our site. We will be ready for a big launch in the fall with NBA and NFL. \n" +
            "Subscribe to our mailing list, we'll keep you in the loop!.",
        "pic": '/images/get-started-2.jpg',
        button: false
    },
];