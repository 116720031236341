import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../@coremat/CmtImage';

const useStyles = makeStyles(() => ({
  logo: {
      "&:hover" : {
          opacity: 0.7
      }
    },
}));

const SymbolLogo = ({ type, ...props }) => {
  const logoUrl = type ===  'symbolText' ? '/images/logo-text-symbol.png' : '/images/logo-symbol.png';
  const classes = useStyles();

  return (
    <Box className="pointer" {...props}>
      <NavLink to="/" >
        <CmtImage className={classes.logo} style={type === 'symbolText' ? {maxWidth: "150px" } : {maxWidth: "55px"}} src={logoUrl} alt="logo" />
      </NavLink>
    </Box>
  );
};

export default SymbolLogo;
