import * as ROUTES from "./routes";
import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Landing from "../pages/Dashboard/Landing/Landing";
import Pricing from "../pages/Pricing";
import SetPassword from "../pages/Auth/SetPassword";
import ContactUs from "../pages/General/ContactUs";
import GameStatsInsights from "../pages/GameStatsInsights";
import GameSingle from "../pages/GameStatsInsights/GameSingle";
import Profile from "../pages/Profile/Profile";
import SetEmail from "../pages/Profile/SetEmail";
import UpcomingLeagues from "../pages/UpcomingLeagues";

export const authRoutes = [
    { exact: true, path: ROUTES.SIGN_IN, component: <SignIn/>},
    { exact: true, path: ROUTES.SIGN_UP, component: <SignUp/>},
    { exact: true, path: ROUTES.FORGOT_PASSWORD, component: <ForgotPassword/>},
    { exact: false, path: ROUTES.VERIFY_ACCOUNT, component: <SetPassword/>},
    { exact: false, path: ROUTES.FORGOT_PASSWORD_VERIFICATION, component: <SetPassword/>},
    { exact: true, path: ROUTES.CONTACT_US, component: <ContactUs/>},
    { exact: true, path: ROUTES.CHANGE_EMAIL_VERIFICATION, component: <SetEmail/>},
]

export const routes = [
    { exact: true, path: ROUTES.HOME, component: <Landing/>},
    { exact: true, path: ROUTES.PRICING, component: <Pricing/>},
    { exact: true, path: ROUTES.INSIGHTS, component: <GameStatsInsights/>},
    { exact: true, path: ROUTES.INSIGHTS_NHL, component: <UpcomingLeagues/>},
    { exact: true, path: ROUTES.INSIGHTS_MLB, component: <UpcomingLeagues/>},
    { exact: true, path: ROUTES.INSIGHTS_NFL, component: <UpcomingLeagues/>},
    { exact: false, path: ROUTES.GAME_DETAILS, component: <GameSingle/>},
    { exact: true, path: ROUTES.PROFILE, component: <Profile/>},
]
