import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {Paper, TableCell, TableContainer, TableRow} from "@material-ui/core";
import NoRecordFound from "../../../pages/GameStatsInsights/GameSingle/Injuries/NoRecordFound";
import useStyles from './index.style';
import SubscriptionTableHead from "./SubscriptionTableHead";
import SubscriptionListRow from "./SubscriptionTableRow";
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptionList, userStateReset} from "../../../vendor/redux/actions/userActions";

const SubscriptionsHistory = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {subscriptionsList, status} = useSelector(state => state.userReducer);

    const initEffect = async () => {
        await dispatch(getSubscriptionList());
    };

    useEffect(initEffect,[]);
    useEffect(() => {if(status === "success") {dispatch(userStateReset())}},[status]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                        <SubscriptionTableHead classes={classes}/>
                        <TableBody>
                            {!!subscriptionsList?.length ?
                                subscriptionsList.map((row, index) => (
                                        <SubscriptionListRow
                                            key={index}
                                            row={row}
                                        />
                                    )
                            ) : (
                                <TableRow style={{ height: 53 * 6 }}>
                                    <TableCell colSpan={7} rowSpan={10}>
                                          <NoRecordFound>{(subscriptionsList && !subscriptionsList.length) ? 'There are no records found.' : 'Loading data...'}</NoRecordFound>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default SubscriptionsHistory;