import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import LineUpsTable from './LineUpsTable';
import {useSelector} from "react-redux";
import TableLabel from "../TableLabel/TableLabel";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 280,
    overflowY: "auto",
    display: "flex",
    width: "100%"
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));

const SeasonSeriesSummary = () => {
  const {headToHeadData} = useSelector((state) => state.singleGameReducer);
  const {gameDetails} = useSelector((state) => state.singleGameReducer);
  const leadership = headToHeadData?.leaders
  const awayTeamLeaders = leadership?.away_team
  const homeTeamLeaders = leadership?.home_team
  const [totalWins, setTotalWins] = useState({
    awayTeam: 0,
    homeTeam: 0
  })

  const classes = useStyles();

  const gameAwayTeamId = gameDetails?.game.away_team?.api_team_id;
  const gameHomeTeamId = gameDetails?.game.home_team?.api_team_id;

  useEffect(() => {
    let awayTeam = 0
    let homeTeam = 0


    headToHeadData?.head_to_head.map(item => {
      let teamData = {
        awayTeamId : item.away_team.api_team_id,
        homeTeamId : item.home_team.api_team_id,
        homeTeamScore : item.home_score_total,
        awayTeamScore : item.away_score_total,
      }

      if((teamData?.awayTeamScore > teamData?.homeTeamScore && teamData?.awayTeamId === gameAwayTeamId) || (teamData?.homeTeamScore > teamData?.awayTeamScore && teamData?.homeTeamId === gameAwayTeamId)) {
        awayTeam++
      } else if((teamData?.awayTeamScore > teamData?.homeTeamScore && teamData?.awayTeamId === gameHomeTeamId) || (teamData?.homeTeamScore > teamData?.awayTeamScore && teamData?.homeTeamId === gameHomeTeamId)) {
        homeTeam++
      }

    })
    setTotalWins({...totalWins, homeTeam: homeTeam, awayTeam: awayTeam})
  }, [headToHeadData?.head_to_head.length])


  return (
      <CmtCard className={classes.cardRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <CmtCardContent>
            {
              awayTeamLeaders && homeTeamLeaders && <TableLabel/>
            }
          </CmtCardContent>
          <CmtCardContent>
            {
              awayTeamLeaders &&  <LineUpsTable color={totalWins?.awayTeam > totalWins?.homeTeam ? 'green' : 'black'}  data={awayTeamLeaders} title={`${gameDetails?.game.away_team.city} ${gameDetails?.game.away_team.name} ${totalWins?.awayTeam}`}/>
            }
          </CmtCardContent>
          <CmtCardContent>
            {
              homeTeamLeaders &&  <LineUpsTable color={totalWins?.homeTeam > totalWins?.awayTeam ? 'green' : "black"}  data={homeTeamLeaders} title={`${gameDetails?.game.home_team.city} ${gameDetails?.game.home_team.name} ${totalWins?.homeTeam}`}/>
            }
          </CmtCardContent>
        </PerfectScrollbar>
      </CmtCard>
  );
};

export default SeasonSeriesSummary;
