import React from "react";
import Typography from "@material-ui/core/Typography";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
import LoadingButton from "../PageComponents/LoadingButton";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({

    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
        justifyContent: "left",
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            fontSize: 13,
        },
    },
    formRoot: {
        alignItems: "center",
        justifyContent: "center",
    },
}));

const MailingList = () => {
    const classes = useStyles();

    return(
        <>
            <Typography component="div" variant="h4" className={classes.titleRoot} mb={2}>
                Choose your email preferences
            </Typography>
            <Box mb={1}>
                <FormControlLabel
                    className={classes.formcontrolLabelRoot}
                    control={<Checkbox />}
                    name="newsletter_subscription"
                    label="Global Newsletter"
                    value={"values.newsletter_subscription"}
                    onChange={""}
                />
            </Box>
            <Box mb={1}>
                <FormControlLabel
                    className={classes.formcontrolLabelRoot}
                    control={<Checkbox />}
                    name="newsletter_subscription"
                    label="NBA Insigts Communications"
                    value={"values.newsletter_subscription"}
                    onChange={""}
                />
            </Box>
            <Box mb={1}>
                <FormControlLabel
                    className={classes.formcontrolLabelRoot}
                    control={<Checkbox />}
                    name="newsletter_subscription"
                    label="Partners Communications"
                    value={"values.newsletter_subscription"}
                    onChange={""}
                />
            </Box>
            <Grid className={classes.formRoot}>
                <LoadingButton
                    loading={""}
                    // disabled={!isValid || isSubmitting}
                    variant="contained"
                    color="primary"
                    titleText="Save"
                />
            </Grid>
        </>
    );
}

export default MailingList;