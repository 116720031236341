import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCEED,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCEED,
} from '../consts'

const initialState = {
    message: null,
    status: null,
    title: null
}

const changePasswordReducer = (state = initialState, action = {} ) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case FORGOT_PASSWORD_SUCCEED:
            return {
                ...state,
                status: "success",
                message: "We have send an email to you for changing your password!",
                title: "Success!"
            };
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                status: "error",
                message: action.payload.email,
                title: "Failed!"
            };
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case CHANGE_PASSWORD_SUCCEED:
            return {
                ...state,
                status: "success",
                message: action.payload,
                title: "Success!"
            };
        default:
            return state;
    }
}

export default changePasswordReducer;