import React from "react"
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import StartingLineups from "./StartingLineups";
import InjuryReport from "./Injuries/InjuryReport";
import NewsFeed from "./NewsFeed";
import GridContainer from "../../../components/GridContainer";

export default function Rosters() {

    return(
        <>
            <GridContainer>
                <Grid item xs={12} md={8}>
                    <Box mb={6}>
                        <StartingLineups/>
                    </Box>
                    <Box mb={6}>
                        <InjuryReport/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box mb={6}>
                        <NewsFeed/>
                    </Box>
                </Grid>
            </GridContainer>

        </>
    )
}