import React from 'react';

import * as ROUTES from "../../../../../route/routes";
import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginSection: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiDivider-root': {
      height: 14,
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));

const HeaderLogin = () => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state) => state.signInReducer);

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" color="warning.main">
      </Box>
      {
        !isLoggedIn &&  <div className={classes.loginSection}>
          <NavLink to={ROUTES.SIGN_IN} className="Cmt-btn" size="small">
            Login
          </NavLink>
          <Divider className="Cmt-divider" orientation="vertical" />
          <NavLink to={ROUTES.SIGN_UP} className="Cmt-btn" size="small">
            Sign up
          </NavLink>
        </div>
      }
    </div>
  );
};

export default HeaderLogin;
