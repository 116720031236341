import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import TableLableHeading from './TableLableHeading';
import TableItem from './TableItem';


const tableHeadings = [
    {
        title: "Scoring Leader"
    },
    {
        title: "Assist Leader"
    },
    {
        title: "Rebound Leader"
    },
    {
        title: "Turnover Leader"
    },
    {
        title: "Steal Leader"
    },
    {
        title: "Block Leader"
    },
]

const TableLabel = () => {

    return (
        <div className="Cmt-table-responsive">
            <Table>
                <TableHead >
                    <TableLableHeading/>
                </TableHead>
                <TableBody>
                    {tableHeadings.map((row, index) => (
                        <TableItem key={index} row={row} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default TableLabel;
