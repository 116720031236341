import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import clsx from "clsx";
import {Box} from "@material-ui/core";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import Typography from "@material-ui/core/Typography";
import CmtMediaObject from "../../../../@coremat/CmtMediaObject";
import CmtImage from "../../../../@coremat/CmtImage";
import {getDateMDY} from "../../../../utils/dateHelper";
import DataLoader from "../../../../components/PageComponents/DataLoader";

const useStyles = makeStyles(theme => ({
    newsContent: {
        display: "flex",
        alignItems: "center",
        marginBottom: "6px"
    },
    newsLink: {
        display: 'inline-block',
        color: '#3f51b5',
        whiteSpace: 'nowrap',
        textOverflow: "ellipsis",
        overflow: 'hidden',
        maxWidth: '100%',

        '&:hover, &:focus': {
            opacity: '0.8'
        },
        '&.active': {
            color: theme.palette.horizontalNav.textActiveColor,
            backgroundColor: theme.palette.horizontalNav.menuActiveBgColor,
            '& .Cmt-icon-root': {
                color: theme.palette.horizontalNav.textActiveColor,
            },
            '&:hover, &:focus': {
                '& .Cmt-icon-root': {
                    color: theme.palette.horizontalNav.textActiveColor,
                },
            },
        },
    },
    newsList : {
        listStyleType: "none",

        "& li": {
            marginLeft: "10px",
            marginRight: "10px",
        }
    },
    scrollbarRoot: {
        maxHeight: 350,
    },
    cardContent: {
        maxHeight: '203px',
        overflowY: "auto",
        marginBottom: "16px"
    },
    searchAction: {
        position: 'relative',
        width: 38,
        height: 38,
    },
    searchActionBar: {
        position: 'absolute',
        right: 0,
        top: 2,
        zIndex: 1,
    },
    root: {
        backgroundColor: "#bdbdbd",
    },
    titleRoot: {
        letterSpacing: 0.15,
        fontSize: 13,
        marginBottom: 8,
        fontWeight: theme.typography.fontWeightRegular,
    },
    avatarProps: {
        width: "50px",
        minWidth: "50px",
        height: "50px",
        marginRight: "0px",
        '@media screen and (max-width: 499px)': {
            marginRight: 0,
            marginBottom: 16,
            width: '100%',
        },
    },
    contentRight: {
        marginLeft: "12px",
        maxWidth: "100%",
        overflow: "hidden",
    }
}));


export default function NewsSingle({newsData, teamName, teamLogo}) {
    const [newsList, setNewsList] = useState([]);
    const classes = useStyles();
    const LanguageDetect = require('languagedetect');
    const langDetect = new LanguageDetect();
    const [loading, setLoading] = useState()
    langDetect.setLanguageType('iso2')

    const getNewsList = () => {
        if(newsData) {
            setLoading(true)
            let news = []
            newsData.map(function(element){
                let detectLanguage = langDetect.detect(element.title, 1)
                if(detectLanguage[0].includes('en')) {
                    news.push(element)
                }
            });
            setNewsList(news.slice(0,6))
            setLoading(false)
        }
    }

    useEffect(() => {getNewsList()}, [])

    return (
        <>
            <CmtCard>
                <CmtCardHeader
                    className={clsx(classes.headerRoot, 'pt-4')}
                    title={`${teamName}`}
                    actionsPos="top-corner">
                </CmtCardHeader>
                <CmtCardContent className={classes.cardContent}>
                    {
                        newsList?.length === 0  && <p>There is no news.</p>
                    }
                    {
                        loading && <DataLoader open={true}/>
                    }
                    {newsList && newsList.map((item, index) => (
                        <Box key={index} className={classes.newsContent}>
                            <CmtMediaObject
                                className={classes.root}
                                avatar={<CmtImage className={classes.avatarProps} src={teamLogo} alt='sdf' />}
                            />
                            <div className={classes.contentRight}>
                                <Typography component="div" variant="h4" mb={1} className={classes.titleRoot}>
                                    <a target='_blank' className={classes.newsLink} href={item.link}>{item.title}</a>
                                </Typography>
                                <Box display="flex" flexWrap="wrap">
                                    <Box mr={1} color="text.disabled">
                                        {getDateMDY(new Date(item.published_date))}
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    ))}
                </CmtCardContent>
            </CmtCard>
        </>
    )
}
