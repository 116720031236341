import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtCard from '../../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import {useDispatch, useSelector} from "react-redux";
import GameChartTable from "../GameChartTable";
import LineUpsTable from "./LineUpsTable";
import {getGameCharts} from "../../../../../../vendor/redux/actions/gamesActions";
import DataLoader from "../../../../../../components/PageComponents/DataLoader";


const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    overflowY: "auto",
    display: "flex",
    width: "100%"
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));



const GameChartTeamTable = ({gameId}) => {
  const {gameCharts, status} = useSelector((state) => state.singleGameReducer);
  const {gameDetails} = useSelector((state) => state.singleGameReducer);
  const homeTeamSeasonsStats =  gameCharts?.season_stats.home_team
  const awayTeamSeasonsStats =  gameCharts?.season_stats.away_team
  const classes = useStyles();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getGameCharts(gameId))
  }, [] )

  return (
      <CmtCard className={classes.cardRoot}>
        {
          status === 'pending' && <DataLoader/>
        }
        {
          status === 'success' && <PerfectScrollbar className={classes.scrollbarRoot}>
            <CmtCardContent>
              <LineUpsTable teamSeasonsStats={awayTeamSeasonsStats} opponentTeamStats={homeTeamSeasonsStats} titleSize={'md'} title={`${gameDetails?.game.away_team.city} ${gameDetails?.game.away_team.name}`}/>
            </CmtCardContent>
            <CmtCardContent>
              <GameChartTable title={'-'}/>
            </CmtCardContent>
            <CmtCardContent>
              <LineUpsTable teamSeasonsStats={homeTeamSeasonsStats} opponentTeamStats={awayTeamSeasonsStats} titleSize={'md'}  title={`${gameDetails?.game.home_team.city} ${gameDetails?.game.home_team.name}`}/>
            </CmtCardContent>
          </PerfectScrollbar>
        }
      </CmtCard>
  );
};

export default GameChartTeamTable;
