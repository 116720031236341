import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {stringify} from "@amcharts/amcharts4/.internal/core/utils/Utils";
import Plan from "../../services/api/plans";

export default function Paypal({plan}) {
    const client_id = "AWIGQVkMVx7NRNhPNbBnX7AfcvQyMDRYe1XD8ojJlhYjeu-Nncsn4ZPo6PKZjKw-0JdjV8pFtnCkZmLx"
    const [paymentType, setPaymentType] = useState(plan?.duration === "ONE" ? "createOrder" : "subscribtion")

    const style = {
        layout: 'horizontal',
        tagline: 'false',
        shape:   'rect',
        label:   'paypal',
    }

    const subscriptionOptions = {
        "client-id": client_id,
        currency: "USD",
        intent: "subscription",
        vault: true,
    };

    const orderOptions = {
        "client-id": client_id,
        currency: "USD",
        intent: "capture",
    }

    const createOrder = (data, actions) => {
        return actions.order.create({
            "purchase_units": [{
                "amount": {
                    "currency_code": "USD",
                    "value": plan?.current_price,
                    "breakdown": {
                        "item_total": {
                            "currency_code": "USD",
                            "value": plan?.current_price,
                        }
                    }
                },
                "items": [
                    {
                        "name": plan?.name,
                        "description": plan?.description,
                        "unit_amount": {
                            "currency_code": "USD",
                            "value": plan?.current_price,
                        },
                        "quantity": "1"
                    },
                ]
            }]
        });
    }

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            'plan_id': plan?.paypal_plan_id
        });
    }

    const postData = async (data) => {
           try {
               await Plan.subscription(data);
           }
           catch (error) {
               return error;
           }
    }

    const onApproveOrder = async (data, actions, details) => {
        const result = await actions.order.capture();
        await postData({
            "plan_id":  plan.id,
            "purchase_date": result.create_time.split("T")[0],
            "paypal_order_id": result.id,
            "paypal_payer_id": result.payer.payer_id,
            "paypal_transaction_id": result.purchase_units[0].payments.captures[0].id,
        });
    }

    const onApproveSubscribtion = async (data, actions, details) => {
        const date = new Date();
        const subscribtionDate = date.toISOString().split("T")[0];
        await postData({
            "purchase_date": subscribtionDate,
            "plan_id":  plan.id,
            "paypal_subscription_id": data.subscriptionID,
        });
    }

    return(
        <Box mt={2}>
            {
                paymentType === "createOrder" ?
                    <PayPalScriptProvider
                        options={orderOptions}>
                        <PayPalButtons
                            style={style}
                            createOrder={createOrder}
                            onApprove={onApproveOrder}
                        />
                    </PayPalScriptProvider> :  <PayPalScriptProvider
                        options={subscriptionOptions}>
                        <PayPalButtons
                            style={style}
                            onApprove={onApproveSubscribtion}
                            createSubscription={paypalSubscribe}
                        />
                    </PayPalScriptProvider>
            }
        </Box>
    )
}