import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";
import {composeWithDevTools} from "redux-devtools-extension";

const { REACT_APP_ENV } = process.env;
const middleWare = [thunk]

const store = createStore(
    reducers,
    REACT_APP_ENV !== 'production' ?
        composeWithDevTools(compose(applyMiddleware(...middleWare)))
        : compose(applyMiddleware(...middleWare)),
)

export default store