import React from 'react';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';

import {useSelector} from "react-redux";
import TeamsRecentGamesModule from "./index";
import {Box} from "@material-ui/core";
import DataLoader from "../../../../../components/PageComponents/DataLoader";

const TeamsRecentGamesReport = () => {
    const {recentGame, gameDetails, recentGameStatus} = useSelector((state) => state.singleGameReducer);

    return (
        <CmtCard>
            <CmtCardContent style={{display: "flex", justifyContent: "space-between"}}>
                {recentGameStatus === "success" &&
                    <>
                        <Box mr={2} width={"50%"}>
                            <TeamsRecentGamesModule
                                data={recentGame?.completed?.away_team}
                                teamId={gameDetails?.game.away_team.api_team_id}
                                teamName={`${gameDetails?.game.away_team.city} ${gameDetails?.game.away_team.name}`}/>
                        </Box>
                        <Box ml={2} width={"50%"}>
                            <TeamsRecentGamesModule
                                data={recentGame?.completed?.home_team}
                                teamId={gameDetails?.game.home_team.api_team_id}
                                teamName={`${gameDetails?.game.home_team.city} ${gameDetails?.game.home_team.name}`}
                            />
                        </Box>
                    </>
                }

                {
                    recentGameStatus === 'pending' && <DataLoader/>
                }
            </CmtCardContent>
        </CmtCard>
    );
};

export default TeamsRecentGamesReport;
