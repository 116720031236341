import React from 'react';
import {Provider} from "react-redux";
import {Routing} from "./route";
import {BrowserRouter, Switch} from "react-router-dom";
import {NotificationContainer} from "react-notifications";
import AppWrapper from "./components/AppWrapper";
import { createBrowserHistory } from "history";
import store from "./vendor/redux/store"
import AppContextProvider from "./components/contextProvider/AppContextProvider";

const customHistory = createBrowserHistory();

function App() {
    return (
        <>
            <Provider store={store}>
                <BrowserRouter customHistory={customHistory}>
                    <AppContextProvider>
                        <AppWrapper>
                            <Switch>
                                <Routing />
                            </Switch>
                            <NotificationContainer />
                        </AppWrapper>
                    </AppContextProvider>
                </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;
