import requests from "./base";

const User = {
    signUp: (data) => requests.post(`api/user/sign-up/`, data),
    signUpGoogle: (data) => requests.post(`api/social/google/`, data),
    signUpFacebook: (data) => requests.post(`api/social/facebook/`, data),
    signIn: (data) => requests.post(`api/user/sign-in/`, data),
    verifyAccount: (uuid) => requests.get(`api/user/verification/${uuid}/`),
    setPassword: (data) => requests.patch(`api/user/set-password/`, data),
    changePassword: (data) => requests.patch(`api/user/change-password/`, data),
    signOut: (data) => requests.post(`api/user/logout/`, data),
    forgotPassword: (data) => requests.post(`api/user/forget-password-send-email/`, data),
    getUserDetails: (data) => requests.get(`api/user/me/`, data),
    refreshToken: (data) => requests.post(`api/user/refresh/`, data),
    contactUs: (data) => requests.post(`api/user/contact-us/`, data, {"Content-Type": "multipart/form-data"}),
    getSubscriptionList: () => requests.get(`api/payment/subscription/`),
    changeEmailRequest: (email) => requests.post('api/user/change-email-request/', email),
    changeEmailVerification: (uuid) => requests.get(`api/user/change-email-verification/${uuid}/`),
    setNewEmail: (config) => requests.patch('api/user/set-new-email/', config),
    userChanges: (id, data) => requests.patch(`api/user/${id}/change/`, data),
};

export default User