import React, { useState } from 'react';
import clsx from 'clsx';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import InfoIcon from '@material-ui/icons/Info';
import {getDateMDY} from "../../../../../utils/dateHelper";




const useStyles = makeStyles(theme => ({
  actionsRoot: {
    transition: 'all 0.3s ease',
    transform: 'translateX(-110%)',
    opacity: 0,
    visibility: 'hidden',
    marginLeft: 10,
  },
  hoverAction: {
    '&:hover $actionsRoot': {
      transform: 'translateX(0%)',
      opacity: 1,
      visibility: 'visible',
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {

      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  winner: {
    color: "green"
  }
}));


const TableItem = ({ row }) => {
  const classes = useStyles();


  return (
    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
      <TableCell className={classes.tableCellRoot}>
        <div>{
          getDateMDY(new Date(row.start_time))}</div>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <div style={{fontWeight: "700"}}>{row.away_team.city} {row.away_team.name}</div>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <span className={row.away_score_total > row.home_score_total && classes.winner} style={{fontWeight: "700"}}><span>{row.away_score_total}</span></span> -
        <span className={row.home_score_total > row.away_score_total && classes.winner} style={{fontWeight: "700"}}> <span>{row.home_score_total}</span></span>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <div>{row.home_team.city} {row.home_team.name}</div>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
