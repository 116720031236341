import React, {useState} from "react";
import {
    Card,
    CardContent, lighten,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Paypal from "../Paypal";

const useStyles = makeStyles(theme => ({
    desc: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        minHeight: "70px",
        display: "inline-block",
        textAlign: "center"
    },
    cardContent: {
        padding: "30px",
        textAlign: "center",
        [theme.breakpoints.down('md')]: {
             padding: "10px",
             paddingTop: "20px"
        },
        '& .MuiListItemIcon-root': { [theme.breakpoints.down('sm')]: {
                minWidth: '10px',
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: '6px',
            },
            [theme.breakpoints.down('md')]: {
                minWidth: '30px',
            },
        },
        '& .MuiListItem-gutters' : {
            [theme.breakpoints.down('sm')]: {
                padding: "0px",
            },
        },
    },
    price: {
        color: "rgba(0, 0, 0, 0.4)",
    },
    button:{
        marginTop: "12px",
    },
}));

const PricingCard = (props) => {
    const {plan, buttonDisabled, activePlan, setActivePlan} = props;
    const classes = useStyles();

    const handlePaypalButtons = () => {
        setActivePlan(plan.id)
    }

    return(
        <Grid item lg={4} md={4} sm={6} xs={12} style={{padding:"8px"}}>
            <Card sx={{ minWidth: 275 }} >
                <CardContent className={classes.cardContent}>
                    <Typography variant="h3" sx={{ fontSize: 14 }} gutterBottom>
                        {plan.name}
                    </Typography>
                    <Typography className={classes.desc}>
                        {plan.description}
                    </Typography>
                    <Box textAlign="center"  md={{mt: 0}}>
                        <Typography ml={1} component="p" className={classes.price}>
                            $ {plan.current_price}
                        </Typography>
                        <Button
                            onClick={() => handlePaypalButtons(plan.id)}
                            disabled={buttonDisabled}
                            variant="contained"
                            className={classes.button}
                            color="primary"
                        >
                            {plan.duration === "ONE"? "Purchase now" : "Subscribe now"}
                        </Button>
                        {
                            activePlan === plan.id &&
                            <Box>
                                <Paypal plan={plan}/>
                            </Box>
                        }
                    </Box>
                    <Box mt={5}>
                        <List component="ul" disablegutters="true">
                            <ListItem disablegutters="true" key={0} style={{paddingBottom: 0, paddingTop: 0}}>
                                <ListItemIcon md={{minWidth: 20}}>
                                    <NavigateNextIcon />
                                </ListItemIcon>
                                <ListItemText primary="Single League"/>
                            </ListItem>
                            <ListItem disablegutters="true" key={1} style={{paddingBottom: 0, paddingTop: 0}}>
                                <ListItemIcon>
                                    <NavigateNextIcon/>
                                </ListItemIcon>
                                <ListItemText primary={`${plan.allowed_cards} cards/month`}/>
                            </ListItem>
                            <ListItem disablegutters="true" key={2} style={{paddingBottom: 0, paddingTop: 0}}>
                                <ListItemIcon>
                                    <NavigateNextIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Standard insights"/>
                            </ListItem>
                            <ListItem disablegutters="true" key={3} style={{paddingBottom: 0, paddingTop: 0}}>
                                <ListItemIcon>
                                    <NavigateNextIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Intelligent insights"/>
                            </ListItem>
                        </List>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default PricingCard;