import React, {useEffect, useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {getDateMDY} from "../../../../../../utils/dateHelper";
import CmtMediaObject from "../../../../../../@coremat/CmtMediaObject";
import CmtAvatar from "../../../../../../@coremat/CmtAvatar";

const useStyles = makeStyles(theme => ({
    titleRoot: {
        marginBottom: 2,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.common.dark,
    },
    textCapitalize: {
        textTransform: "capitalize"
    },
    td: {
        paddingTop: "19px",
        paddingBottom: "19px",
        borderTop: "1px solid rgba(224,224,224)"
    },
    img: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        textAlign: "center",
        backgroundColor: "#bdbdbd",
        marginRight: "12px",

        '& img': {
            maxWidth: "100%"
        }
    },
    win: {
        color: "green",
        marginRight: "5px"
    },
    lost: {
        color: "red",
        marginRight: "5px"
    },
}));

const TeamsRecentGamesListRow = ({ row, teamId }) => {
    const classes = useStyles();

    return (
        <>
            {
                row && <tr>
                    <td className={classes.td}>
                    </td>
                    <td className={classes.td}>
                        <p style={{fontWeight: 600}}>{row.start_time}</p>
                    </td>
                    <td className={classes.td}>
                        <div style={{display: "flex", alignItems: "center"}}>
                          <span style={{marginRight: "6px"}}>
                              {teamId === row.home_team.api_team_id ? "VS" : "@"}
                          </span>
                            <div className={classes.img}>
                                <img src={teamId === row.home_team.api_team_id ? row.away_team.official_logo_src : row.home_team.official_logo_src} alt="Logo"/>
                            </div>
                            {
                                teamId === row.home_team.api_team_id ? row.away_team.abbreviation : row.home_team.abbreviation
                            }
                        </div>
                    </td>
                    {teamId === row.home_team.api_team_id &&
                    <td className={classes.td}>
                        {
                            row.home_score_total > row.away_score_total ?
                                <span className={classes.win}>W</span> : <span className={classes.lost}>L</span>}
                        {
                            `${row.away_score_total}` +  '-'  + `${row.home_score_total}`
                        }
                    </td>
                    }
                    {teamId === row.away_team.api_team_id &&
                    <td className={classes.td}>
                        {row.away_score_total > row.home_score_total ?
                            <span className={classes.win}>W</span> : <span className={classes.lost}>L</span>}
                        {
                            `${row.away_score_total}` +  '-'  + `${row.home_score_total}`
                        }
                    </td>
                    }
                </tr>
            }
        </>

    );
};

export default TeamsRecentGamesListRow;
