import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {format, parseISO} from "date-fns";
import {Box, TextField} from "@material-ui/core";
import makeStyles from "material-ui-core/styles/makeStyles";
import GameCard from "../GameCard";
import CmtAdvCard from "../../../@coremat/CmtAdvCard";
import {
    getGameDetails,
    getGamePlayers,
    getGames,
} from "../../../vendor/redux/actions/gamesActions";
import DataLoader from "../../../components/PageComponents/DataLoader";
import ManageSubscription from "./ManageSubscription";

const useStyles = makeStyles(theme => ({
    box: {
        position: 'relative'
    },
    notifyButton: {
        position: 'absolute!important',
        right: '10px',
        top: '10px',
        zIndex: '2'
    },
    notifyIconOn: {
        color: '#fea621!important',
    },
    notifyIconOff: {
        color: 'rgba(255,255,255,0.7)'
    },
    gameDetails: {
        marginLeft: '24px',
        [theme.breakpoints.down("sm")]: {
            marginLeft: '0px',
            marginTop: "25px",
        },
    },
    form: {
        display:"flex",
        justifyContent: "center"
    },
    datePicker: {
        marginBottom: "24px",
        padding: "16px 8px"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    card: {
        cursor: "pointer"
    },
    dateField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 250,
        position: 'relative',

        ' & input' : {
            color: 'transparent !important',
        }
    },

    gameDate: {
        position: 'relative',
        ' & label' : {
            position: 'absolute',
            background: "#ffffff",
            left: '0px',
            paddingLeft: "20px",
            paddingTop: "4px",
            bottom: "5px"
        }
    },

    linkDisabled: {
        pointerEvents: "none",
        opacity: "0.5"
    },
    noData: {
        padding: "16px 8px",
        textAlign: "center"
    }
}));

export default function Games() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const {games, status} = useSelector((state) => state.gamesReducer);


    const onCardClick = async (gameId) => {
        await dispatch(getGameDetails(gameId))
        await dispatch(getGamePlayers(gameId))
        history.push(   `/stats-and-insights/game-details/${gameId}`)
    }

    useEffect(() => {
        dispatch(getGames(startDate))
    }, [startDate])

    useEffect(() => {}, [status])

    return (
        <>
            <Box className={classes.gameDetails}>
                <CmtAdvCard mb={3} className={classes.datePicker}>
                    <form noValidate className={classes.form}>
                        <Box className={classes.gameDate}>
                            <TextField
                                label="Game Date"
                                type="date"
                                onChange={(e) => {setStartDate(e.target.value)}}
                                defaultValue={startDate}
                                className={classes.dateField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <label>
                                {format(parseISO(startDate), 'EEEE, MMMM d')}
                            </label>
                        </Box>
                    </form>
                </CmtAdvCard>
                {
                    status === 'pending' &&  <DataLoader open={true}/>
                }
                {
                    status === 'success' && games?.length === 0 && <Box width="100%">
                        <CmtAdvCard className={classes.noData}>
                            There are no games.
                        </CmtAdvCard>
                    </Box>
                }
                {
                    games?.length > 0 && status !== 'pending' && games?.map((game) =>
                       <Box className={classes.box} key={game.api_game_id}>
                         <ManageSubscription gameId={game.api_game_id} subscriptionStatus={game.subscription_status} />
                           <Box onClick={() => onCardClick(game.api_game_id)} className={classes.card} >
                               <GameCard teamTotals={game.team_total_stats} game={game}  />
                           </Box>
                       </Box>
                    )
                }
            </Box>
        </>
    )
}