import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CmtCard from '../../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import ListEmptyResult from '../../../../../../@coremat/CmtList/ListEmptyResult';
import CmtList from '../../../../../../@coremat/CmtList';
import ScoringTabs from './ScoringTabs';
import GameChartTable from "./ScoringTable/GameChartTable";
import AwayTeamLinesUpsTable from "./ScoringTable/GameQuarterTeamData/AwayTeamLinesUpsTable";
import {useSelector} from "react-redux";
import HomeTeamLineUpsTable from "./ScoringTable/GameQuarterTeamData/HomeTeamLineUpsTable";
import DataLoader from "../../../../../../components/PageComponents/DataLoader";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  headerRoot: {
    padding: '24px',
    textAlign: 'center',
    position: 'relative',
    borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    [theme.breakpoints.down('xs')]: {
      '&.Cmt-header-root': {
        flexDirection: 'column',
      },
      '& .Cmt-action-default-menu': {
        position: 'absolute',
        right: 24,
        top: 5,
      },
    },
  },
  cardContentRoot: {
    padding: '0 !important',
  },
  newsListRoot: {
    padding: 24,
    cursor: 'pointer',
    transition: 'all .2s',

    '& .Cmt-media-object': {
      width: '100%',
    },
    '& .fav-btn': {
      transform: 'scale(0)',
      transition: 'all .2s',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      '& .fav-btn': {
        transform: 'scale(1)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .Cmt-media-object': {
        flexDirection: 'column',
      },
      '& .Cmt-media-image': {
        width: '100%',
        alignSelf: 'normal',
        marginBottom: 10,
        '& img': {
          marginRight: 0,
          width: '100%',
        },
      },
    },
  },
  titleRoot: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 16,
    },
  },
}));

const tabItems =  [
  { id: 1, name: 'First Half', slug:  'first-half'},
  { id: 2, name: 'Second Half', slug: 'second-half' },
  { id: 3, name: 'First Quarter', slug: 1 },
  { id: 4, name: 'Second Quarter', slug: 2 },
  { id: 5, name: 'Third Quarter', slug: 3 },
  { id: 6, name: 'Fourth Quarter', slug: 4 },
]

const NewsList = ({
                    currentTab,
                    setCurrentTab,
                    data,
                  }) => {
  const classes = useStyles();
  const {status} = useSelector((state) => state.singleGameReducer);

  useEffect(() => {}, [])

  return (
      <CmtCard>
        <CmtCardHeader
            className={classes.headerRoot}
            title={
              <Box>
                <Typography component="div" variant="h4" className={classes.titleRoot}>
                  Season Quarter Scoring Average
                </Typography>
              </Box>
            }
        >
        </CmtCardHeader>
        <ScoringTabs tabItems={tabItems} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <CmtCardContent className={classes.cardContentRoot}>
          <CmtList
              data={data}
              renderRow={(item, index) => {
                return (
                    <CmtCard key={index} className={classes.cardRoot}>
                      {
                        status === 'pending' && <DataLoader />
                      }
                      {
                        status === 'success' &&
                        <>
                          <CmtCardContent>
                            <AwayTeamLinesUpsTable/>
                          </CmtCardContent>
                          <CmtCardContent>
                            <GameChartTable title={'-'}/>
                          </CmtCardContent>
                          <CmtCardContent>
                            <HomeTeamLineUpsTable/>
                          </CmtCardContent>
                        </>
                      }
                    </CmtCard>
                );
              }}
              ListEmptyComponent={<ListEmptyResult title="No Result" content="No result found with your search" />}
          />
        </CmtCardContent>
      </CmtCard>
  );
};

export default NewsList;
