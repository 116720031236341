import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import {useSelector} from "react-redux";
import LineUpsTable from "./LineUpsTable";
import TableLabel from "./TableLabel/TableLabel";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    overflowY: "auto",
    display: "flex",
    width: "100%"
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));

const BettingLines = () => {

  const classes = useStyles();
  const {gamblingData} = useSelector((state) => state.singleGameReducer);

  const moneyLineAway = gamblingData?.game_betting_line.money_line?.moneyLine?.awayLine?.decimal
  const pointSpreadAway = gamblingData?.game_betting_line.point_spread?.pointSpread?.awaySpread
  const pointSpreadDecimalAway = gamblingData?.game_betting_line.point_spread?.pointSpread?.awayLine.decimal
  const overLineAway = gamblingData?.game_betting_line?.over_under?.overUnder?.overLine.decimal
  const overUnder = gamblingData?.game_betting_line?.over_under?.overUnder?.overUnder

  const moneyLineHome = gamblingData?.game_betting_line.money_line?.moneyLine?.homeLine?.decimal
  const pointSpreadHome = gamblingData?.game_betting_line.point_spread?.pointSpread?.homeSpread
  const pointSpreadDecimalHome = gamblingData?.game_betting_line.point_spread?.pointSpread?.homeLine.decimal
  const overLineHome = gamblingData?.game_betting_line?.over_under?.overUnder?.overLine.decimal


  return (
      <CmtCard className={classes.cardRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <CmtCardContent>
            {
              gamblingData && <LineUpsTable
                  moneyLine={moneyLineAway}
                  pointSpread={pointSpreadAway}
                  pointSpreadDecimal={pointSpreadDecimalAway}
                  overLine={overLineAway}
                  overUnder={overUnder}
                  overLineText={"O"}
              />
            }
          </CmtCardContent>
          <CmtCardContent>
            <TableLabel/>
          </CmtCardContent>
          <CmtCardContent>
          {
            gamblingData && <LineUpsTable
                moneyLine={moneyLineHome}
                pointSpread={pointSpreadHome}
                pointSpreadDecimal={pointSpreadDecimalHome}
                overLine={overLineHome}
                overUnder={overUnder}
                overLineText={"U"}
            />
          }
          </CmtCardContent>
        </PerfectScrollbar>
      </CmtCard>
  );
};

export default BettingLines;
