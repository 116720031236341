import React, {useEffect} from 'react';
import SideBar from "../../components/AppLayout/partials/SideBar";
import {Box} from "material-ui-core";
import {useDispatch, useSelector} from "react-redux";
import {getLeagues} from "../../vendor/redux/actions/gamesActions";
import Games from "./Games";
import makeStyles from "material-ui-core/styles/makeStyles";
import {leaguesList} from "../../components/AppLayout/partials/menus";
import Newsfeed from "./Newsfeed/Newsfeed";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    gamesList: {
        width: "70%",
        [theme.breakpoints.down("sm")]: {
           width: "100%"
        },
    },
    newsfeed: {
        width: "30%",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
    },
    sideBarRoot: {
        width: 75,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

export default function GameStatsInsights() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {leagues} = useSelector((state) => state.leaguesReducer);

    useEffect(() => {
        dispatch(getLeagues());
    }, [])

    return(
        <>
            <Box display={"flex"} height={'100%'} className={classes.root}>
                {
                    leagues &&
                    <Box>
                        <SideBar sidebarNavs={leaguesList} title={"Leagues"} type={"item"}/>
                    </Box>
                }
                <Box className={classes.gamesList}>
                    <Games/>
                </Box>
                <Box className={classes.newsfeed}>
                    <Newsfeed/>
                </Box>
            </Box>
        </>
    )
}