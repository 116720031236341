import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';

import useStyles from './index.style';
import NoRecordFound from './NoRecordFound';
import TeamsRecentGamesToolbar from "./TeamsRecentGamesToolbar";
import TeamsRecentGamesTableHead from "./TeamsRecentGamesTableHead";
import TeamsRecentGamesListRow from "./TeamsRecentGamesListRow";

const TeamsRecentGamesModule = ({data, teamName, teamId}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TeamsRecentGamesToolbar
            teamName={teamName}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TeamsRecentGamesTableHead />
            <TableBody>
              {data?.length ? (
                  data?.map((row, index) => (
                    <TeamsRecentGamesListRow
                      key={index}
                      teamId={teamId}
                      row={row}
                    />
                  )
              )) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={5} rowSpan={10}>
                    <NoRecordFound>{!data?.length && 'There are no records found.'}</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default TeamsRecentGamesModule;
