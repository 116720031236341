import {
    SET_LOGIN,
    SET_USER_REQUEST,
    SET_USER_SUCCEED,
    SET_USER_FAILURE,
    RESET_STATE,
} from "../consts";

const initialState = {
    isLoggedIn: localStorage.getItem("jwt-access-token") ? true : false,
    signInStatus: null,
    data: null,
    user: null,
}



const signInReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_LOGIN:
            return {
                ...state,
                isLoggedIn: action.payload,
                signInStatus: null,
            };
        case SET_USER_REQUEST:
            return {
                ...state,
                signInStatus: "pending",
                message: "",
            };
        case SET_USER_SUCCEED:
            return {
                ...state,
                user: action.payload,
                signInStatus: "success",
                message: "",
                isLoggedIn: true,
            };
        case SET_USER_FAILURE:
            return {
                ...state,
                signInStatus: "error",
                message: "No user found with the given credentials",
            };
        case RESET_STATE:
            return {
                ...state,
                signInStatus: null,
                data: null
            }
        default:
            return state;
    }
};

export default signInReducer;