import {
    SET_USER_REQUEST,
    SET_USER_SUCCEED,
    SET_USER_FAILURE,
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCEED,
    CONTACT_US_FAILURE,
    RESET_STATE,
    GET_SUBSCRIPTIONS_REQUEST,
    GET_SUBSCRIPTIONS_SUCCEED,
    USER_CHANGES_REQUEST,
    USER_CHANGES_SUCCEED,
    CHANGE_EMAIL_SUCCEED,
    CHANGE_EMAIL_REQUEST,
} from "../consts";

const initialState = {
    user: {},
    status: null,
    contactStatus: null,
    message: "",
    subscriptionsList: null,
};

const userReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_USER_REQUEST:
            return {
                ...state,
                status: 'pending',
                message: "",
            };
        case SET_USER_SUCCEED:
            return {
                ...state,
                user: action.payload,
                message: "",
                IsLoggedIn: true,
            };
        case SET_USER_FAILURE:
            return {
                ...state,
                status: 'error',
                message: action.message,
            };
        case CONTACT_US_REQUEST:
            return {
                ...state,
                contactStatus: 'pending',
                message: "",
            };
        case CONTACT_US_SUCCEED:
            return {
                ...state,
                user: action.payload,
                contactStatus: 'success',
                title: "Form submitted!",
                message: "You contact us form has been successfully submitted, Will contact you back ASAP.",
                IsLoggedIn: true,
            };
        case CONTACT_US_FAILURE:
            return {
                ...state,
                contactStatus: 'error',
                title: 'Failed',
                message: "An error occurerd while submitting the contact us form"
            };
        case GET_SUBSCRIPTIONS_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case GET_SUBSCRIPTIONS_SUCCEED:
            return {
                ...state,
                status: "success",
                subscriptionsList: action.payload,
            };
        case USER_CHANGES_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case USER_CHANGES_SUCCEED:
            return {
                ...state,
                status: "success",
            };
        case CHANGE_EMAIL_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case CHANGE_EMAIL_SUCCEED:
            return {
                ...state,
                status: "success",
                message: action.payload,
            };
        case RESET_STATE:
            return  {
                ...state,
                status: null,
                title: null,
                message: null
            }
        default:
            return state;
    }
};

export default userReducer;
