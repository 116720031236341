import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import MuiAlert from '@material-ui/lab/Alert';
import PricingCard from "../components/PricingCard/PricingCard";
import {getPlans} from "../vendor/redux/actions/plansActions";
import DataLoader from "../components/PageComponents/DataLoader";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    loderRoot: {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems:"center"
    },
    contentRoot: {
        height: "100%",
    },
}));

export default function Pricing() {
    const classes = useStyles()
    const [activePlan, setActivePlan] = useState();
    const {plans, status} = useSelector((state) => state.plansReducer);
    const {isLoggedIn} = useSelector(state => state.signInReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPlans())
    }, [])

    return(
        <Box height="100%">
            {!isLoggedIn && <MuiAlert elevation={6} variant="filled" severity="warning" style={{margin:"8px"}} >
                Please sign in to select a pricing plan or purchasing an item!
            </MuiAlert>
            }
            <Grid container className={classes.contentRoot}>
                {status === "pending" &&
                <Grid container className={classes.loderRoot}>
                    <DataLoader />
                </Grid>
                }
                {
                    plans?.map((plan, index) =>
                       <PricingCard
                           key={index}
                           plan={plan}
                           buttonDisabled={!isLoggedIn}
                           activePlan={activePlan}
                           setActivePlan={setActivePlan}
                       />
                    )
                }
            </Grid>
        </Box>
    )
}



