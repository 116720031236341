import React from 'react';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import {Divider} from "material-ui-core";
import InjuriesModule from "./index";
import {useSelector} from "react-redux";

const InjuryReport = () => {
    const {gameDetails} = useSelector((state) => state.singleGameReducer);
    const homeTeamPlayers = useSelector((state) => state.singleGameReducer.gamePlayers.home_team_player);
    const awayTeamPlayers = useSelector((state) => state.singleGameReducer.gamePlayers.away_team_player);
    const homeTeamInjuriesPlayers = homeTeamPlayers.filter(player => {
        return player.current_injury_description && !player.lineup_position
    })
    const awayTeamInjuriesPlayers = awayTeamPlayers.filter(player => {
        return player.current_injury_description && !player.lineup_position
    })

    return (
        <CmtCard>
            <CmtCardHeader title="Injury Report" />
            <Divider/>
            <CmtCardContent>
                <InjuriesModule data={awayTeamInjuriesPlayers} mb={3} teamName={`${gameDetails?.game.away_team.city} ${gameDetails?.game.away_team.name}`}/>
                <InjuriesModule data={homeTeamInjuriesPlayers} mb={3} teamName={`${gameDetails?.game.home_team.city} ${gameDetails?.game.home_team.name}`}/>
            </CmtCardContent>
        </CmtCard>
    );
};

export default InjuryReport;
