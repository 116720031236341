import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import LineUpsTable from './LineUpsTable';
import {useSelector} from "react-redux";
import DataLoader from "../../../../../components/PageComponents/DataLoader";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    overflowY: "auto",
    display: "flex",
    width: "100%"
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));

const RegularSeasonSeriesTable = () => {

  const {headToHeadData, status} = useSelector((state) => state.singleGameReducer);
  const headToHead  = headToHeadData?.head_to_head

  const classes = useStyles();
  return (
      <CmtCard className={classes.cardRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <CmtCardContent>
            {
              status === 'pending' && <DataLoader />
            }
            {
              headToHead && status !== 'pending' && <LineUpsTable data={headToHead}/>
            }
          </CmtCardContent>
        </PerfectScrollbar>
      </CmtCard>
  );
};

export default RegularSeasonSeriesTable;
