import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import TableHeading from './TableHeading';
import TableItem from './TableItem';

const LineUpsTable = ({ data, title }) => {
  return (
    <div className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableHeading title={title} />
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableItem key={row.api_game_id} row={row} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default LineUpsTable;
