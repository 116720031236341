import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';

import useStyles from './index.style';
import NoRecordFound from './NoRecordFound';
import InjuryListRow from "./InjuryListRow";
import InjuryTableHead from "./InjuryTableHead";
import InjuryTableToolbar from "./InjuryTableToolbar";

const InjuriesModule = ({data, teamName}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <InjuryTableToolbar
            teamName={teamName}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <InjuryTableHead
              rowCount={data.length}
            />
            <TableBody>
              {!!data.length ? (
                  data.map((row, index) => (
                    <InjuryListRow
                      key={index}
                      row={row}
                    />
                  )
              )) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={5} rowSpan={10}>
                    <NoRecordFound>{!data.length ? 'There are no records found.' : 'Loading players...'}</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default InjuriesModule;
