import {
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCEED,
    GET_PAYMENTS_REQUEST,
    GET_PAYMENTS_SUCCEED, PLANS_RESET,
} from "../consts";
import Plan from "../../../services/api/plans";

export function getPlans ()  {
    return async (dispatch) => {
        dispatch({type: GET_PAYMENTS_REQUEST});
        try {
            const response = await Plan.getPlan();
            if (response.status === 200) {
                dispatch({
                    type: GET_PAYMENTS_SUCCEED,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function cancelSubscription(id)  {
    return async (dispatch) => {
        dispatch({type: CANCEL_SUBSCRIPTION_REQUEST});
        try {
            const response = await Plan.cancelSubscription(id);
            if (response.status === 204) {
                dispatch({
                    type: CANCEL_SUBSCRIPTION_SUCCEED,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function plansReset() {
    return dispatch => dispatch({type: PLANS_RESET});
}


