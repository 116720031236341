import {Backdrop, CircularProgress} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import React from "react";

const useStyles = makeStyles(theme => ({
    circularProgressRoot: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1, color: '#fff'
    }
}));

const PageLoader = ({open, children}) => {
    const classes = useStyles();
    return (
        <div>
            <Backdrop
                className={classes.backdrop}
                open={open}
            >
                <Box className={classes.circularProgressRoot}>
                    <CircularProgress size={60} />
                </Box>
                {children}
            </Backdrop>
        </div>
    );
}

export default PageLoader;