import React from "react";
import BannerSlider from "./BannerSlider";

export default function Landing() {

    return(
        <>
            <BannerSlider/>
        </>
    )
}