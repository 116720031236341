import React, {useEffect} from "react"
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RecentGamesData from "./RecentGamesData";
import {useDispatch, useSelector} from "react-redux";
import {getRecentGame, getStatsGame} from "../../../vendor/redux/actions/gamesActions";
import TeamsRecentGamesReport from "./RecentGamesData/TeamsRecentGames/TeamsRecentGamesReport";

const useStyles = makeStyles(() => ({
    profileMainContent: {
        '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
}));

export default function RecentGames({gameId}) {
    const { isLoggedIn } = useSelector((state) => state.signInReducer);
    const classes = useStyles();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRecentGame(gameId));
        if(isLoggedIn) {
            dispatch(getStatsGame(gameId))
        }
    }, [])

    return(
        <>
             <Grid item xs={12} className={classes.profileMainContent}>
                    <Box mb={6}>
                    <RecentGamesData />
                    </Box>
                    <Box mb={6}>
                        <TeamsRecentGamesReport/>
                    </Box>
                </Grid>
        </>
    )
}