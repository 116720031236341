import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import {useSelector} from "react-redux";
import TableLabel from "./TableLabel/TableLabel";
import AwayTeamHistoryData from "./AwayTeamHistoryData";
import HomeTeamHistoryData from "./HomeTeamHistoryData";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    flexWrap: "wrap",
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    overflowY: "auto",
    display: "flex",
    width: "100%"
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));

const RegularSeasonHistory = () => {

  const classes = useStyles();
  const {gamblingData, gameDetails} = useSelector((state) => state.singleGameReducer);
  const {game} = gameDetails
  const awayTeamTitle = `${game?.away_team.city} ${game?.away_team.name}`
  const homeTeamTitle = `${game?.home_team.city} ${game?.home_team.name}`
  const history = gamblingData?.history


  return (
      <>
        <CmtCard className={classes.cardRoot}>
          {history.map((data, index) => {
            return (
                <>
                  <PerfectScrollbar className={classes.scrollbarRoot} key={index} >
                    <CmtCardContent>
                      <AwayTeamHistoryData
                          data={data}
                          title={awayTeamTitle}
                      />
                    </CmtCardContent>
                    <CmtCardContent>
                      <TableLabel
                          awayTeamInjuries={data.game.injuries.away_team}
                          homeTeamInjuries={data.game.injuries.home_team}
                      />
                    </CmtCardContent>
                    <CmtCardContent>
                      {
                        <HomeTeamHistoryData
                            data={data}
                            title={homeTeamTitle}
                        />
                      }
                    </CmtCardContent>
                  </PerfectScrollbar>
                </>
            );
          })}
        </CmtCard>
        {
          history.length === 0 && <p style={{textAlign: "center"}}>No data to show.</p>
        }
      </>
  );
};

export default RegularSeasonHistory;
