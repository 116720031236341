import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import TableHeading from './TableHeading';
import TableItem from './TableItem';

const LineUpsTable = ({ currentStreak, data, title, titleSize }) => {
  return (
    <div className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableHeading titleSize={titleSize} title={title} />
        </TableHead>
        <TableBody>
            <TableItem currentStreak={currentStreak} stats={data} />
        </TableBody>
      </Table>
    </div>
  );
};

export default LineUpsTable;
