import React, {useEffect} from 'react'
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import {Divider} from "material-ui-core";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import DataLoader from "../../../../components/PageComponents/DataLoader";

import {getGamblingData} from "../../../../vendor/redux/actions/gamesActions";
import {useDispatch, useSelector} from "react-redux";
import BettingLines from "./BettingLines";
import RegularSeasonHistory from "./RegularSeasonHistory";

export default function GamblingCenter({gameId}) {
    const dispatch = useDispatch()
    const {gamblingData, status} = useSelector((state) => state.singleGameReducer);


    const init = () => {
        dispatch(getGamblingData(gameId))
    }

    useEffect(() => {init()}, []);


    return(
        <>
            <CmtCard style={{marginBottom: "24px"}}>
                <CmtCardHeader style={{textAlign: "center"}} title="Betting Lines" />
                <Divider/>
                <CmtCardContent>
                    {
                        status === 'pending' &&
                        <DataLoader />
                    }
                    {
                        gamblingData && status !== 'pending' && <BettingLines/>
                    }
                </CmtCardContent>
            </CmtCard>
            <CmtCard style={{marginBottom: "24px"}}>
                <CmtCardHeader style={{textAlign: "center"}}  title="Regular Season History" />
                <Divider/>
                <CmtCardContent>
                    {
                        status === 'pending' &&
                        <DataLoader />
                    }
                    {
                        gamblingData && status !== 'pending' && <RegularSeasonHistory/>
                    }
                </CmtCardContent>
            </CmtCard>
        </>
    )
}