import React from 'react';
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import {Divider} from "material-ui-core";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import StartingLineUpsTable from "./StartingLineUpsTable";

export default function StartingLineups() {
    return(
        <>
            <CmtCard>
                <CmtCardHeader style={{textAlign: "center"}} title="Starting Lineups" />
                <Divider/>
                <CmtCardContent>
                    <StartingLineUpsTable/>
                </CmtCardContent>
            </CmtCard>
        </>
    )
}