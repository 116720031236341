import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Product Name' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'price', numeric: false, disablePadding: false, label: 'Price' },
    { id: 'nextCharge', numeric: false, disablePadding: false, label: 'Next Charge' },
    { id: 'expiration', numeric: false, disablePadding: false, label: 'Expiration Date' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

function SubscriptionTableHead({ classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
    const onSortOrderChange = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            disabled={true}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={onSortOrderChange(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

SubscriptionTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default React.memo(SubscriptionTableHead);