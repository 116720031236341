import React, {createContext, useContext, useEffect, useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import { Box, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Button from "@material-ui/core/Button";
import {CommonComponentsContext} from "../../../route";
import {cancelSubscription, plansReset} from "../../../vendor/redux/actions/plansActions";
import plansReducer from "../../../vendor/redux/reducers/plansReducer";
import {getSubscriptionList} from "../../../vendor/redux/actions/userActions";

const useStyles = makeStyles(theme => ({
    titleRoot: {
        marginBottom: 2,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.common.dark,
        textAlign: "center",
    },
    buttonRoot: {
        color: "white",
        backgroundColor: "#f10a0a",
    },
}));

// const getUserActions = user => {
//     const actions = [
//         { action: 'view', label: 'View', icon: <Visibility /> },
//         { action: 'edit', label: 'Edit', icon: <Edit /> },
//         { action: 'email', label: 'Email', icon: <Mail /> },
//     ];
//
//     if (user.status === 'active') {
//         actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
//     } else {
//         actions.push({
//             action: 'activate',
//             label: 'Reactivate',
//             icon: <CheckCircleOutline />,
//         });
//     }

//     actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
//     return actions;
// };

const SubscriptionListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {setDialogOpen, setModalOptions} = useContext(CommonComponentsContext);
    const [planId, setPlanId] = useState("");
    const {status} = useSelector(state => state.plansReducer);

    const onOpenDialog = (id) => {
        setDialogOpen(true);
        setPlanId(id);
    };
    const onCloseDialog = () => {setDialogOpen(false)};

    const initEffect = () => {
        setModalOptions({
            onConfirm: () => dispatch(cancelSubscription(planId)),
            title: "Unsubscribe",
            content: "Are you sure about unsubscribing?",
        })
    }

    const succeedEffect = async () => {
        if(status === "success") {
           await dispatch(getSubscriptionList());
           dispatch(plansReset());
           onCloseDialog();
        }
    };
    useEffect(initEffect,[planId]);
    useEffect(succeedEffect,[status])
    // const labelId = `enhanced-table-checkbox-${row.id}`;
    // const isItemSelected = isSelected(row.id);
    // const userActions = getUserActions(row);

    return (
        <TableRow
            hover
            // onClick={event => onRowClick(event, row.id)}
            role="checkbox"
            // aria-checked={isItemSelected}
            tabIndex={-1}
            // key={row.id}
            // selected={isItemSelected}
            >
            <TableCell component="th"
                       // id={labelId}
                       scope="row">
                <Box display="flex" alignItems="center">
                    <div>
                        <Typography className={classes.titleRoot} component="div" variant="h4">
                            {row.plan.name}
                        </Typography>
                    </div>
                </Box>
            </TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.plan.current_price}</TableCell>
            <TableCell>{row.renewal_date}</TableCell>
            <TableCell>{row.expiration_date}</TableCell>
            <TableCell>
                <Button
                    disabled={row.status === "CANCELLED"}
                    onClick={() => onOpenDialog(row.id)}
                    variant="contained"
                    color="error"
                    className={classes.buttonRoot}
                >
                    Unsubscribe
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default React.memo(SubscriptionListRow);
