import {
    GET_GAMBLING_FAILURE,
    GET_GAMBLING_REQUEST, GET_GAMBLING_SUCCEED,
    GET_GAME_CHART_REQUEST,
    GET_GAME_CHART_SUCCEED,
    GET_GAME_HEAD_TO_HEAD_REQUEST,
    GET_GAME_HEAD_TO_HEAD_SUCCEED,
    GET_GAME_PLAYERS_REQUEST,
    GET_GAME_PLAYERS_SUCCEED,
    GET_GAME_QUARTER_FAILURE,
    GET_GAME_QUARTER_REQUEST,
    GET_GAME_QUARTER_SUCCEED,
    GET_GAMES_REQUEST,
    GET_GAMES_SUCCEED,
    GET_LEAGUES_REQUEST,
    GET_LEAGUES_SUCCEED, GET_LIVE_FAILURE, GET_LIVE_REQUEST, GET_LIVE_SUCCEED,
    GET_RECENT_GAME_REQUEST,
    GET_RECENT_GAME_SUCCEED,
    GET_SINGLE_GAME_REQUEST,
    GET_SINGLE_GAME_SUCCEED,
    GET_STATS_GAME_REQUEST,
    GET_STATS_GAME_SUCCEED,
    UPDATE_GAME_SUBSCRIPTION_FAILURE,
    UPDATE_GAME_SUBSCRIPTION_REQUEST,
    UPDATE_GAME_SUBSCRIPTION_SINGLE_REQUEST,
    UPDATE_GAME_SUBSCRIPTION_SINGLE_SUCCEED,
    UPDATE_GAME_SUBSCRIPTION_SUCCEED
} from "../consts";
import Game from "../../../services/api/games";

export function getLeagues() {
    return async (dispatch) => {
        dispatch({type: GET_LEAGUES_REQUEST});
        try {
            const response = await Game.getLeagues();
            if (response.status === 200) {
                dispatch({
                    type: GET_LEAGUES_SUCCEED,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function getGames(startDate, token) {
    return async (dispatch) => {
        dispatch({type: GET_GAMES_REQUEST});
        try {
            const response = await Game.getGames(startDate, token);
            if (response.status === 200) {
                dispatch({
                    type: GET_GAMES_SUCCEED,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function getGameDetails(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_SINGLE_GAME_REQUEST});
        try {
            const response = await Game.getGameDetails(gameId);
            if (response.status === 200) {
                dispatch({
                    type: GET_SINGLE_GAME_SUCCEED,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function getGamePlayers(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_GAME_PLAYERS_REQUEST});
        try {
            const response = await Game.getGamePlayers(gameId);
            if (response.status === 200) {
                dispatch({
                    type: GET_GAME_PLAYERS_SUCCEED,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function updateGameSubscription(gameId, data) {
    return async (dispatch) => {
        dispatch({type: UPDATE_GAME_SUBSCRIPTION_REQUEST});
        try {
            const response = await Game.updateGameSubscription(gameId, data)
            dispatch({
                type: UPDATE_GAME_SUBSCRIPTION_SUCCEED,
                payload: response.data,
                status: 'success',
            })
        } catch (error) {
            dispatch({
                type: UPDATE_GAME_SUBSCRIPTION_FAILURE,
                status: 'error'
            })
        }
    }
}

export function updateGameSubscriptionSingle(gameId, data) {
    return async (dispatch) => {
        dispatch({type: UPDATE_GAME_SUBSCRIPTION_SINGLE_REQUEST});
        try {
            const response = await Game.updateGameSubscription(gameId, data)
            dispatch({
                type: UPDATE_GAME_SUBSCRIPTION_SINGLE_SUCCEED,
                payload: response.data,
                status: 'success',
            })
        } catch (error) {
            dispatch({
                type: UPDATE_GAME_SUBSCRIPTION_FAILURE,
                status: 'error'
            })
        }
    }
}

export function getRecentGame(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_RECENT_GAME_REQUEST});
        try {
            const response = await Game.getRecentGame(gameId);
                dispatch({
                    type: GET_RECENT_GAME_SUCCEED ,
                    payload: response.data,
                    recentGameStatus: 'success'
                })
        }
        catch (error) {
            return error;
        }
    }
}

export function getStatsGame(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_STATS_GAME_REQUEST});
        try {
            const response = await Game.getsStatsGame(gameId);
            if (response.status === 200) {
                dispatch({
                    type: GET_STATS_GAME_SUCCEED ,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function getGameCharts(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_GAME_CHART_REQUEST});
        try {
            const response = await Game.getGameCharts(gameId);
            if (response.status === 200) {
                dispatch({
                    type: GET_GAME_CHART_SUCCEED ,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function getQuarterCharts(gameId, quarter) {
    return async (dispatch) => {
        dispatch({type: GET_GAME_QUARTER_REQUEST});
        try {
            const response = await Game.getGameQuarter(gameId, quarter);
                dispatch({
                    type: GET_GAME_QUARTER_SUCCEED ,
                    payload: response.data,
                    status: 'success'
                })
        }
        catch (error) {
            dispatch(
                {
                    type: GET_GAME_QUARTER_FAILURE
                }
            )
        }
    }
}

export function getTeamsHeadToHead(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_GAME_HEAD_TO_HEAD_REQUEST});
        try {
            const response = await Game.getGameHeadToHead(gameId);
            if (response.status === 200) {
                dispatch({
                    type: GET_GAME_HEAD_TO_HEAD_SUCCEED ,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function getGamblingData(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_GAMBLING_REQUEST});
        try {
            const response = await Game.getGamblingData(gameId);
            if (response.status === 200) {
                dispatch({
                    type: GET_GAMBLING_SUCCEED ,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            dispatch(
                {
                    type: GET_GAMBLING_FAILURE
                }
            )
        }
    }
}

export function getLiveData(gameId) {
    return async (dispatch) => {
        dispatch({type: GET_LIVE_REQUEST});
        try {
            const response = await Game.getLiveData(gameId);
            if (response.status === 200) {
                dispatch({
                    type: GET_LIVE_SUCCEED ,
                    payload: response.data,
                    status: 'success'
                })
            }
        }
        catch (error) {
            dispatch(
                {
                    type: GET_LIVE_FAILURE,
                    status: 'error'
                }
            )
        }
    }
}

