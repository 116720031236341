import React, {useEffect} from 'react';
import SideBar from "../../components/AppLayout/partials/SideBar";
import {Box} from "material-ui-core";
import makeStyles from "material-ui-core/styles/makeStyles";
import {leaguesList} from "../../components/AppLayout/partials/menus";
import CmtAdvCard from "../../@coremat/CmtAdvCard";
import NewsLatterSubscription from "../../components/Common/NewsLatterSubscription";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    cardContent: {
        padding: '24px'
    },
    contentRight: {
        marginLeft: '24px',
        [theme.breakpoints.down("sm")]: {
            marginLeft: '0px',
        },
    },
    toUppercase: {
        textTransform: 'uppercase'
    }
}));

export default function UpcomingLeagues() {
    const classes = useStyles();
    const location = useLocation();
    const leagueName = location.pathname.slice(location.pathname.length - 3)

    return(
        <>
            <Box display={"flex"} height={'100%'} className={classes.root}>
                {
                    <Box>
                        <SideBar sidebarNavs={leaguesList} title={"Leagues"} type={"item"}/>
                    </Box>
                }
                <Box className={classes.contentRight}>
                    <Box width="100%" mb={4}>
                        <CmtAdvCard className={classes.cardContent}>
                            <p>
                                We are working on adding <span className={classes.toUppercase}>{leagueName}</span> stats and insights. Want to stay informed?
                                Subscribe to our notification list to receive updates when new leagues are added.
                            </p>
                        </CmtAdvCard>
                    </Box>

                    <Box width="100%">
                        <CmtAdvCard>
                            <NewsLatterSubscription title="Subscribe to mailing list."/>
                        </CmtAdvCard>
                    </Box>
                </Box>
                </Box>

        </>
    )
}