import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import { Box, alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Toolbar from '@material-ui/core/Toolbar';
import SidebarToggleHandler from '../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler';
import Hidden from "@material-ui/core/Hidden";
import UserDropDown from '../../partials/UserDropDown';
import CmtHorizontal from "../../../../@coremat/CmtNavigation/Horizontal";
import {horizontalDefaultNavs} from "../../partials/menus";
import SymbolLogo from "../../partials/SymbolLogo";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: 10,
    padding: 0,
    marginBottom: 30,
    [theme.breakpoints.down('lg')]: {
      padding: 7,
      minHeight: 100,
      marginBottom: 0,
    },
    '& .Cmt-appIcon': {
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },
}));

const HeaderMain = () => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state) => state.signInReducer);

  useEffect(() => {
  }, [isLoggedIn]);

  return (
      <Toolbar className={classes.root}>
        <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
        <SymbolLogo  type='symbolText' mr={{ xs: 2, sm: 4, lg: 6, xl: 8 }} />
        <Box display="flex" alignItems="center" ml="auto">
          <Hidden mdDown>
            <CmtHorizontal menuItems={horizontalDefaultNavs} />
          </Hidden>
          {
            isLoggedIn &&
            <>
              <UserDropDown />
            </>
          }
        </Box>
      </Toolbar>
  );
};

export default HeaderMain;
