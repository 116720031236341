import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    circularProgressRoot: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 999999,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const LoadingButton = (props) => {
    const {titleText, loading, disabled} = props;
    const classes = useStyles();

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mt: 4, position: "relative" }}>
                <Button type='submit' disabled={disabled} variant="contained" color="primary">
                    {titleText}
                </Button>
                {loading && (
                    <Box className={classes.circularProgressRoot}>
                        <CircularProgress size={24} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default LoadingButton;
