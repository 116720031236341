import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './index.style';



const TeamsRecentGamesToolbar = ({teamName}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Toolbar>
                <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                    {teamName}{' '}
                </Typography>
            </Toolbar>
        </React.Fragment>
    );
};

export default React.memo(TeamsRecentGamesToolbar);
