import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {alpha, makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Box, TextField} from "@material-ui/core";
import {userChangesSchema as validationSchema} from "../../utils/yup-schemas/authSchemas";
import LoadingButton from "../PageComponents/LoadingButton";
import {changeEmailRequest, userChanges, userStateReset} from "../../vendor/redux/actions/userActions";
import {fetchUserDetails} from "../../vendor/redux/actions/authActions";
import showNotificationMessage from "../../utils/notifications";

const useStyles = makeStyles(theme => ({

    titleRoot: {
        marginBottom: 14,
        margin: 10,
        color: theme.palette.text.primary,
        justifyContent: "left",
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            fontSize: 13,
        },
    },
    formRoot: {
        alignItems: "center",
        justifyContent: "center",
    },
    googleBtn: {
        width: "100%"
    }
}));

const PersonalInfo = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.signInReducer);
    const {status, message} = useSelector(state => state.userReducer);

    const onSubmit = async (values) => {
        const {email, ...restData} = values;
        if(Object.keys(restData).find(key => user[key] !== restData[key])) {
           await dispatch(userChanges(user.id, restData));
        }
        if(user.email !== values.email) {
           await dispatch(changeEmailRequest({email: values.email}));
        }
    }

    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
    };

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
        isSubmitting,
        resetForm,
        setValues,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const initValues = () => {
        if(status) {dispatch(userStateReset())};
        if(user?.id) {
            setValues({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                dob: user.dob,
            })
        }
    }

    const isChanged = () => {
        if(values.first_name) {
            return Object.keys(values).find(key => user[key] !== values[key]);
        }
    };

    const fetchUpdatedDetails = () => {
        if(status) {
            switch (status) {
                case 'success':
                    showNotificationMessage(status, message || 'Your profile details has successfully updated');
                    dispatch(fetchUserDetails());
                    break;
                case 'error':
                    showNotificationMessage(status, message);
                    break;
            }
        }
    };

    useEffect(initValues,[user]);
    useEffect(fetchUpdatedDetails,[status]);

    return(
        <>
            <form onSubmit={handleSubmit}>
                <Box>
                    <TextField
                        label="First Name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='first_name'
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.first_name &&
                            Boolean(errors.first_name)
                        }
                        helperText={
                            errors.first_name
                        }
                    />
                </Box>
                <Box>
                    <TextField
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='last_name'
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.last_name &&
                            Boolean(errors.last_name)
                        }
                        helperText={
                            errors.last_name
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.email &&
                            Boolean(errors.email)
                        }
                        helperText={
                            errors.email
                        }
                    />
                </Box>
                <Box mb={2}>
                <TextField
                    label="Date of Birth"
                    type="date"
                    variant="outlined"
                    name="dob"
                    required
                    fullWidth
                    value={values.dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textFieldRoot}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                </Box>
                <Grid className={classes.formRoot}>
                    <LoadingButton
                        loading={""}
                        disabled={!isValid || !isChanged()}
                        variant="contained"
                        color="primary"
                        titleText="Save"
                    />
                </Grid>
            </form>
        </>
    );
}

export default PersonalInfo;