import React from 'react';
import { useDropzone } from 'react-dropzone';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {alpha, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    dropzone: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
}));


const FilePicker = ({ onAddAttachments, onRejectAttachment, text }) => {
    const classes = useStyles();
    const { getRootProps, getInputProps } = useDropzone({
        maxSize: 1000000,
        accept: 'image/*, .pdf, .zip',
        multiple: true,
        onDropAccepted: acceptedFiles => {
            const files = acceptedFiles.map(file => {
                return {
                    id: Math.floor(Math.random() * 10000),
                    file,
                };
            });
            onAddAttachments(files);
        },
        onDropRejected: rejectedFiles => {
            const files = rejectedFiles.map(file => {
                return {
                    id: Math.floor(Math.random() * 10000),
                    file,
                };
            });
            onRejectAttachment(files);
        },
    });

    return (
        <Box{...getRootProps({ className: classes.dropzone })}>
            <label>
                <input {...getInputProps()} />
                <span style={{marginRight: "6px", color: text.color}}>{text.message}</span>
                <Tooltip title="Attachments">
                    <IconButton >
                        <AttachFileIcon />
                    </IconButton>
                </Tooltip>
            </label>
        </Box>
    );
};

export default FilePicker;

FilePicker.prototype = {
    onAddAttachments: PropTypes.func,
};