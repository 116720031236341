import React from 'react';
import clsx from 'clsx';
import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import { Box } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import {signOut} from "../../../vendor/redux/actions/authActions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {getRefreshToken} from "../../../vendor/helpers/storage";
import * as ROUTES from "../../../route/routes";

const useStyles = makeStyles(theme => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    marginLeft: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
}));

const actionsList = [
  {
    icon: <PersonIcon />,
    label: 'Account',
  },
  {
    icon: <ExitToAppIcon />,
    label: 'Logout',
  },
];

const UserDropDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory()

  const onItemClick = item => {
    switch (item.label) {
      case 'Logout':
        dispatch(signOut({ refresh: getRefreshToken() }, history));
        break;

      case 'Account':
        history.push(ROUTES.PROFILE);
        break;
    };
  };

  return (
    <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
      <CmtDropdownMenu
        onItemClick={onItemClick}
        TriggerComponent={<CmtAvatar size="small" />}
        items={actionsList}
      />
    </Box>
  );
};

export default UserDropDown;
