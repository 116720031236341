import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {useFormik} from "formik";
import {Box, Checkbox,FormControlLabel, TextField} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import {format} from "date-fns";
import { alpha, makeStyles } from '@material-ui/core/styles';
import {CurrentAuthMethod} from "../../constants/AppConstants";
import * as ROUTES from "../../route/routes";
import {signUpSchema as
        validationSchema} from "../../utils/yup-schemas/authSchemas";
import 'react-notifications/lib/notifications.css';
import showNotificationMessage from "../../utils/notifications";
import {SignUpUser} from "../../vendor/redux/actions/authActions";
import {SocialSignUp} from "./SocialSignUp";
import LoadingButton from "../../components/PageComponents/LoadingButton";

const useStyles = makeStyles(theme => ({

    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            fontSize: 13,
        },
    },
    googleBtn: {
        width: "100%"
    }
}));

export default function SignUp({method = CurrentAuthMethod,  variant = 'default', wrapperVariant = 'default' })  {
    const classes = useStyles({ variant });
    const dispatch = useDispatch()
    const {signUpStatus, message, title} = useSelector(state => state.signUpReducer)
    let maxDate = (format(new Date(), 'yyyy-MM-dd'));
    const [authProvider, setAuthProvider]= useState();

    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        security_subscription: true,
        newsletter_subscription: false,
    };

    const onSubmit = async (values) => {
        values.security_subscription = true;
        setAuthProvider('email')
        await dispatch(SignUpUser('email', values));
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
        isSubmitting,
        resetForm,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    useEffect(() => {
        if(authProvider === 'email') {
            switch (signUpStatus) {
                case 'success':
                    showNotificationMessage(signUpStatus, 'Success The verification link has been sent to your email address', title)
                    resetForm();
                    break;
                case 'error':
                    showNotificationMessage(signUpStatus, message, title)
                    break;
            }
        }
    }, [signUpStatus])

    return(
        <>
            <Typography component="div" variant="h1" className={classes.titleRoot}>
                Sign up
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box>
                    <TextField
                        label="First Name"
                        fullWidth
                        margin="normal"
                        required
                        variant="outlined"
                        className={classes.textFieldRoot}
                        name='first_name'
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.first_name &&
                            Boolean(errors.first_name)
                        }
                        helperText={
                            errors.first_name
                        }
                    />

                </Box>
                <Box>
                    <TextField
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='last_name'
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.last_name &&
                            Boolean(errors.last_name)
                        }
                        helperText={
                            errors.last_name
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.email &&
                            Boolean(errors.email)
                        }
                        helperText={
                            errors.email
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="DOB"
                        margin="normal"
                        type="date"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="dob"
                        value={values.dob}
                        onChange={handleChange}
                        inputProps={{ max: maxDate }}
                        onBlur={handleBlur}
                        error={
                            touched.dob &&
                            Boolean(errors.dob)
                        }
                        helperText={
                            errors.dob
                        }
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        className={classes.formcontrolLabelRoot}
                        control={<Checkbox checked/>}
                        label="By submitting I agree to the terms and conditions"
                        name="terms"
                    />
                </Box>
                <Box mb={3}>
                    <FormControlLabel
                        className={classes.formcontrolLabelRoot}
                        control={<Checkbox />}
                        name="newsletter_subscription"
                        label="By submitting I agree to receive newsletter"
                        value={values.newsletter_subscription}
                        onChange={handleChange}
                    />
                </Box>
                <Box mb={5}>
                    <Typography>
                        Have an account?
                        <NavLink className="ml-1" to={ROUTES.SIGN_IN}>Sign in</NavLink>
                    </Typography>
                </Box>
                <LoadingButton
                    loading={signUpStatus === "pending"}
                    disabled={!isValid || isSubmitting}
                    variant="contained"
                    color="primary"
                    titleText="Register"
                />
            </form>
            {/*<SocialSignUp titleText={'SIGN UP'}/>*/}
        </>
    )
}

