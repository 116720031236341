import {SET_PASSWORD_SUCCEED} from "../consts";

const initialState = {
    status: null,
    data: null
}

const setUserPassword = (state = initialState, action = {}) => {

    switch(action.type) {
        case SET_PASSWORD_SUCCEED: return {
            ...state,
            data: action.payload,
            status: 'success'
        };
        default:
            return state;
    }
}

export default setUserPassword;