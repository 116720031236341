import {
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCEED,
    CONTACT_US_FAILURE,
    GET_SUBSCRIPTIONS_REQUEST,
    GET_SUBSCRIPTIONS_SUCCEED,
    USER_CHANGES_REQUEST,
    USER_CHANGES_SUCCEED,
    CHANGE_EMAIL_REQUEST,
    CHANGE_EMAIL_SUCCEED,
    RESET_STATE,
} from "../consts";
import User from "../../../services/api/user";

export function contactUs(values) {
    return async (dispatch) => {
        dispatch({type: CONTACT_US_REQUEST});
        try {
            const response = await User.contactUs(values);
            if(response.status === 200) {
                dispatch({
                    type: CONTACT_US_SUCCEED,
                    payload: response,
                });
            } else {
                dispatch({
                    type: CONTACT_US_FAILURE,
                    payload: response
                })
            }
        } catch (error) {
            return error;
        }
    };
}

export function getSubscriptionList() {
    return async (dispatch) => {
        dispatch({type: GET_SUBSCRIPTIONS_REQUEST});
        try {
            const response = await User.getSubscriptionList();
            if (response.status === 200) {
                dispatch({
                    type: GET_SUBSCRIPTIONS_SUCCEED,
                    payload: response.data.results,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function userChanges(id, data) {
    return async (dispatch) => {
        dispatch({type: USER_CHANGES_REQUEST});
        try {
            const response = await User.userChanges(id, data);
            if (response.status === 200) {
                dispatch({
                    type: USER_CHANGES_SUCCEED,
                    payload: response.data.results,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function changeEmailRequest(email) {
    return async (dispatch) => {
        dispatch({type: CHANGE_EMAIL_REQUEST});
        try {
            const response = await User.changeEmailRequest(email);
            if (response.status === 200) {
                dispatch({
                    type: CHANGE_EMAIL_SUCCEED,
                    payload: response.data.message,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function setNewEmail(config) {
    return async (dispatch) => {
        dispatch({type: CHANGE_EMAIL_REQUEST});
        try {
            const response = await User.setNewEmail(config);
            if (response.status === 200) {
                dispatch({
                    type: CHANGE_EMAIL_SUCCEED,
                    payload: response.data.results,
                    status: 'success'
                })
            }
        }
        catch (error) {
            return error;
        }
    }
}

export function userStateReset() {
    return dispatch => dispatch({type: RESET_STATE});
}