import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import TableLableHeading from './TableLableHeading';
import TableItem from './TableItem';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getDateMDY} from "../../../../../../utils/dateHelper";
import {parseISO} from "date-fns";

const useStyles = makeStyles(theme => ({
    highlightedText: {
        fontWeight: 600,
        textAlign: "center",
        textDecoration: "underline"
    },
    textSmall: {
        fontSize: "12px",
        fontStyle: "italic",
        textAlign: "center"
    },
    injuriesList: {
        listStyleType: "none",
        display: "inline",
        paddingLeft: "0",

        '& li' : {
            display: "inline",
            '&:last-child span' : {
                display: "none"
            }
        }
    }
}));

const bettingLinesData = [
    {
        title: "Final Score",
    },
    {
        title: "Money Line",
    },
    {
        title: "Point Spread",
    },
    {
        title: "Over Under",
    }
]

const TableLabel = ({awayTeamInjuries, homeTeamInjuries}) => {
    const classes = useStyles()
    const {gameDetails} = useSelector((state) => state.singleGameReducer);
    const awayTeamName = `${gameDetails?.game?.away_team?.city} ${gameDetails?.game?.away_team?.name}`
    const homeTeamName = `${gameDetails?.game?.home_team?.city} ${gameDetails?.game?.home_team?.name}`
    const startDate =  getDateMDY(parseISO(gameDetails?.game?.start_time))

    return (
        <div className="Cmt-table-responsive">
            <Table>
                <TableHead >
                    <TableLableHeading title={startDate}/>
                </TableHead>
                <TableBody>
                    {bettingLinesData.map((row, index) => (
                        <TableItem key={index} row={row} />
                    ))}
                    <TableRow>
                        <TableCell style={{fontWeight: 600, borderBottom: "none", borderTop: "1px solid rgba(224, 224, 224, 1)"}} >
                            <div className={classes.textSmall}>
                                <span className={classes.highlightedText}>*Injuries</span>{" : "}
                                <span>{awayTeamName}</span> {"- "}
                                <ul className={classes.injuriesList}>
                                    {awayTeamInjuries.length > 0 ?  awayTeamInjuries.map((item, index) => (
                                        <li key={index}>
                                            {item.player.first_name}  {item.player.last_name}<span>, </span>
                                        </li>)) : <li>No Injuries</li>
                                    }
                                </ul>
                            </div>
                            <div className={classes.textSmall}>
                                <span>{homeTeamName}</span> {"- "}
                                <ul className={classes.injuriesList}>
                                    {homeTeamInjuries.length > 0 ?  homeTeamInjuries.map((item, index) => (
                                        <li key={index}>
                                            {item.player.first_name}  {item.player.last_name}<span>, </span>
                                        </li>)) : <li>No Injuries</li>
                                    }
                                </ul>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

export default TableLabel;
