import React, {useEffect} from 'react';
import {Box, TextField} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {NavLink, useHistory} from "react-router-dom";
import * as ROUTES from "../../route/routes";
import {useFormik} from "formik";
import {signInSchema as
        validationSchema} from "../../utils/yup-schemas/authSchemas";
import {useDispatch, useSelector} from "react-redux";
import {resetState, signInUser} from "../../vendor/redux/actions/authActions";
import showNotificationMessage from "../../utils/notifications";
import LoadingButton from "../../components/PageComponents/LoadingButton";

const useStyles = makeStyles(theme => ({
    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
            align: "center"
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
    },
}));

export default function SignIn({ variant = 'default', wrapperVariant = 'default' })  {
    const classes = useStyles({ variant });
    const dispatch = useDispatch();
    const {signInStatus, message, title} = useSelector(state => state.signInReducer)
    const history = useHistory();

    const initialValues = {
        email: '',
        password: ''
    }

    const onSubmit = async (values) => {
        await dispatch(signInUser(values));
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
        isSubmitting,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    useEffect(() => {
        if(signInStatus === 'error') {
            showNotificationMessage(signInStatus, message, "Sign in failed!");
            dispatch(resetState());
        } else if (signInStatus === 'success') {
            history.goBack()
        }
    }, [signInStatus])

    return(
        <>
            <Typography component="div" variant="h1" className={classes.titleRoot}>
                Login
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldRoot}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.email &&
                            Boolean(errors.email)
                        }
                        helperText={
                            errors.email
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        type="password"
                        label="Password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldRoot}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.password &&
                            Boolean(errors.password)
                        }
                        helperText={
                            errors.password
                        }
                    />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                    <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                        <NavLink to={ROUTES.FORGOT_PASSWORD}>
                            Forgot password?
                        </NavLink>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                    <LoadingButton
                        loading={signInStatus === "pending"}
                        disabled={!isValid || isSubmitting}
                        variant="contained"
                        color="primary"
                        titleText="Sign in"
                    />
                    <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                        <NavLink to={ROUTES.SIGN_UP}>
                           Sign up
                        </NavLink>
                    </Box>
                </Box>
                {/*<SocialSignUp titleText={'SIGN IN'}/>*/}
            </form>
        </>
    )
}

