import React from 'react';
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import {Divider} from "material-ui-core";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import {useSelector} from "react-redux";
import NewsSingle from "./NewsSingle";

export default function NewsFeed() {
    const {gameDetails} = useSelector((state) => state.singleGameReducer);
    const awayTeamNews = gameDetails.game.away_team.rss_data
    const homeTeamNews = gameDetails.game.home_team.rss_data
    const awayTeamName = `${gameDetails?.game.away_team.city} ${gameDetails?.game.away_team.name}`
    const homeTeamName = `${gameDetails?.game.home_team.city} ${gameDetails?.game.home_team.name}`
    const awayTeamLogo = gameDetails?.game.away_team.official_logo_src
    const homeTeamLogo= gameDetails?.game.home_team.official_logo_src

    return(
        <>
            <CmtCard>
                <CmtCardHeader style={{textAlign: "center"}} title="NewsFeed" />
                <Divider/>
                <CmtCardContent style={{display: "flex", flexWrap: "wrap"}}>
                    {
                        awayTeamNews && <div style={{width: "100%", marginBottom: "12px"}}>
                            <NewsSingle teamLogo={awayTeamLogo} teamName={awayTeamName} newsData={awayTeamNews}/>
                        </div>
                    }
                    {
                        homeTeamNews && <div style={{width: "100%"}}>
                            <NewsSingle teamLogo={homeTeamLogo} teamName={homeTeamName} newsData={homeTeamNews}/>
                        </div>
                    }
                </CmtCardContent>
            </CmtCard>
        </>
    )
}