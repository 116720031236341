import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableItem from './TableItem';
import TableHeading from "./GameQuarterTeamData/TableHeading";
import {TableHead} from "@material-ui/core";

const RecentGameTableData = [
    {
        title: "LATEST BETTING LINE",
    },
    {
        title: "HALF WINNING %",
    },
    {
        title: "AVG POINTS SCORED",
    }
    // {
    //   title: "AVG POINTS ALLOWED",
    // },
    // {
    //   title: "AVG POINTS MARGIN",
    // },
]

const GameChartTable = () => {
  return (
    <div className="Cmt-table-responsive">
      <Table>
          <TableHead>
          <TableHeading/>
          </TableHead>
        <TableBody>
          {RecentGameTableData.map((row, index) => (
            <TableItem key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default GameChartTable;
