import {GET_GAMES_REQUEST, GET_LEAGUES_FAILURE, GET_LEAGUES_REQUEST, GET_LEAGUES_SUCCEED} from "../consts";

const initialState = {
    status: null,
    leagues: null,
}

const leaguesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_LEAGUES_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_LEAGUES_SUCCEED:
            return {
                ...state,
                status: "success",
                leagues: action.payload
            };
        case GET_LEAGUES_FAILURE:
            return {
                ...state,
                status: "error",
            };
        case GET_GAMES_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        default:
            return state;
    }
}

export default leaguesReducer;