import {VERIFY_ACCOUNT_FAILURE, VERIFY_ACCOUNT_SUCCEED} from "../consts";

const initialState = {
    status: null,
    data: null
}

const verifyAccountReducer = (state = initialState, action = {}) => {

    switch(action.type) {
        case VERIFY_ACCOUNT_SUCCEED: return {
            ...state,
            data: action.payload.user,
            status: 'success',
        };
        case VERIFY_ACCOUNT_FAILURE: return {
            ...state,
            data: null,
            status: 'error'
        };
        default:
            return state;
    }
}

export default verifyAccountReducer;