import React, {useEffect, useState} from 'react'
import makeStyles from "material-ui-core/styles/makeStyles";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {getDateMDY} from "../../../utils/dateHelper";
import clsx from "clsx";
import {useSelector} from "react-redux";
import DataLoader from "../../../components/PageComponents/DataLoader";
const defaultImage = '/images/icons/news.svg'

const useStyles = makeStyles(theme => ({
    newsfeed: {
        marginLeft: "24px",

        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginLeft: "0"
        },
    },
    newsContent: {
        display: "flex",
        alignItems: "center",
        marginBottom: "6px",
    },
    newsLink: {
        display: 'inline-block',
        color: '#3f51b5',
        whiteSpace: 'nowrap',
        textOverflow: "ellipsis",
        overflow: 'hidden',
        maxWidth: '100%',

        '&:hover, &:focus': {
            opacity: '0.8'
        },
        '&.active': {
            color: theme.palette.horizontalNav.textActiveColor,
            backgroundColor: theme.palette.horizontalNav.menuActiveBgColor,
            '& .Cmt-icon-root': {
                color: theme.palette.horizontalNav.textActiveColor,
            },
            '&:hover, &:focus': {
                '& .Cmt-icon-root': {
                    color: theme.palette.horizontalNav.textActiveColor,
                },
            },
        },
    },
    newsList : {
        listStyleType: "none",

        "& li": {
            marginLeft: "10px",
            marginRight: "10px",
        }
    },
    scrollbarRoot: {
        maxHeight: 350,
    },
    cardContent: {
        maxHeight: '500px',
        overflowY: "auto",
        marginBottom: "16px"
    },
    searchAction: {
        position: 'relative',
        width: 38,
        height: 38,
    },
    searchActionBar: {
        position: 'absolute',
        right: 0,
        top: 2,
        zIndex: 1,
    },
    root: {
        backgroundColor: "#bdbdbd",
        minWidth: "50px",
        height: "50px",
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center center`,
        backgroundSize: 'cover',
    },
    titleRoot: {
        letterSpacing: 0.15,
        fontSize: 13,
        marginBottom: 8,
        fontWeight: theme.typography.fontWeightRegular,
    },
    avatarProps: {
        width: "50px",
        minWidth: "50px",
        height: "50px",
        marginRight: "0px",
        '@media screen and (max-width: 499px)': {
            marginRight: 0,
            marginBottom: 16,
            width: '100%',
        },
    },
    contentRight: {
        marginLeft: "12px",
        maxWidth: "100%",
        overflow: "hidden",
    }
}));

export default function
    Newsfeed() {
    const classes = useStyles();
    const [newsList, setNewsList] = useState([]);
    const {newsfeed} = useSelector((state) => state.gamesReducer);
    const [loading, setLoading] = useState(true);

    const getNewsList = async () => {
        if(newsfeed) {
            newsfeed.map(function(element){
                element.sort((a, b) => b.date - a.date)
                let slicedNews = element.slice(0,4);
                slicedNews.forEach(item => setNewsList(newsList => [...newsList, item]))
                setLoading(false)
            });
        }
    }

    useEffect(() => {getNewsList()}, [newsfeed])

    return(
        <>
            {
                newsList && <CmtCard className={classes.newsfeed}>
                    <CmtCardHeader
                        className={clsx(classes.headerRoot, 'pt-4')}
                        title="Latest News"
                        actionsPos="top-corner">
                    </CmtCardHeader>
                    <CmtCardContent className={classes.cardContent}>

                        {
                            newsList?.length === 0 && !loading && <p>There is no news.</p>
                        }
                        {
                            loading && <DataLoader open={true}/>
                        }
                        {newsList && newsList.map((item, index) => (
                            <Box key={index} className={classes.newsContent}>
                                <div
                                    className={classes.root}
                                    style={{  backgroundImage:`url(${item.image ? item.image : defaultImage })`}}
                                />
                                <div className={classes.contentRight}>
                                    <Typography component="div" variant="h4" mb={1} className={classes.titleRoot}>
                                        <a target='_blank' className={classes.newsLink} href={item.link}>{item.title}</a>
                                    </Typography>
                                    <Box display="flex" flexWrap="wrap">
                                        <Box mr={1} color="text.disabled">
                                            {getDateMDY(new Date(item.published_date))}
                                        </Box>
                                    </Box>
                                </div>
                            </Box>
                        ))}
                    </CmtCardContent>
                </CmtCard>
            }

        </>
    )
}