import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import {Notifications, NotificationsOff} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import makeStyles from "material-ui-core/styles/makeStyles";

import {
    getGameDetails,
    updateGameSubscription,
    updateGameSubscriptionSingle
} from "../../../vendor/redux/actions/gamesActions";
import {useHistory} from "react-router-dom";
import * as ROUTES from '../../../route/routes'

const useStyles = makeStyles(theme => ({

    notifyButton: {
        position: 'absolute!important',
        right: '10px',
        top: '10px',
        zIndex: '2'
    },
    notifyIconOn: {
        color: '#fea621!important',
    },
    notifyIconOff: {
        color: 'rgba(255,255,255,0.7)'
    },
}));

export default function ManageSubscription({gameId, subscriptionStatus, gameSingle}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {user, isLoggedIn} = useSelector(state => state.signInReducer);
    const history = useHistory();

    const updateSubscriptionDetails = async (gameId, subscriptionStatus) => {
        if(!isLoggedIn) {
            history.push(ROUTES.SIGN_IN)
        } else {
            const subscriptionData = {
                user: user?.id,
                user_name: user?.first_name,
                user_email: user?.email,
                status: subscriptionStatus === 'active' ? 'inactive' : 'active'
            }
            if(gameSingle === 'gameSingle') {
                await dispatch(updateGameSubscriptionSingle(gameId, subscriptionData))
            } else if(!gameSingle) {
                await dispatch(updateGameSubscription(gameId, subscriptionData))
            }
        }
    }
    return (
        <>
            {
                <Button className={classes.notifyButton}  onClick={() => {updateSubscriptionDetails(gameId,subscriptionStatus)}} >
                    <Tooltip  placement="top" title="Click to be notified for any news and trends regarding this game.">
                        <span aria-label="Click to be notified for any news and trends regarding this game/">
                            {subscriptionStatus === 'active' ?
                                <Notifications className={classes.notifyIconOn}/>
                                : <NotificationsOff className={classes.notifyIconOff}/>}
                        </span>
                    </Tooltip>
                </Button>
            }
        </>
    )
}