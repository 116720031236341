import React from 'react';
import CmtCard from '../../../@coremat/CmtCard';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',
    padding: '12px',
    '& .Cmt-card-content': {
      height: '100%',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  stayInTouch: {
    color: theme.palette.text.secondary,
    fontSize: "16px !important",
    marginBottom: "25px !important",
  },
  input_Btn: {
    display: "flex"
  },
  btnRoot: {
    marginLeft: "12px",
    minWidth: "120px",
    fontSize: "12px"
  },
}));

const NewsLatterSubscription = ({title}) => {
  const classes = useStyles();
  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Typography component="div" variant="h2" className={classes.stayInTouch}>
          {
            title ? title : "Stay in touch! Join our newsletter."
          }

        </Typography>
        <Box className={classes.input_Btn}>
          <TextField fullWidth id="uncontrolled" label="Email address" defaultValue="example@gmail.com" variant="outlined" />
          <Button className={classes.btnRoot} variant="contained" color="primary" htmltype="submit">
            Subscribe
          </Button>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default NewsLatterSubscription;
