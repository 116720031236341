import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  tableRowCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: "center",
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
}));

const TableHeading = ({title, subtitle}) => {
  const classes = useStyles();
  return (
      <>
    <TableRow>
      <TableCell className={classes.tableRowCellRoot}>{title}</TableCell>
    </TableRow>
  <TableRow>
      <TableCell className={classes.tableRowCellRoot}><h5>{subtitle}</h5></TableCell>
    </TableRow>
        </>
  );
};

export default TableHeading;
