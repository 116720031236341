export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCEED = "SIGN_UP_SUCCEED";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const VERIFY_ACCOUNT_REQUEST = "VERIFY_ACCOUNT_REQUEST";
export const VERIFY_ACCOUNT_SUCCEED = "VERIFY_ACCOUNT_SUCCEED";
export const VERIFY_ACCOUNT_FAILURE = "VERIFY_ACCOUNT_FAILURE";
export const RESET_STATE = "RESET_STATE";

export const SET_LOGIN = "SET_LOGIN";

export const SET_USER_REQUEST = "SET_USER_REQUEST";
export const SET_USER_SUCCEED = "SET_USER_SUCCEED";
export const SET_USER_FAILURE = "SET_USER_FAILURE";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCEED = "SET_PASSWORD_SUCCEED";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCEED = "FORGOT_PASSWORD_SUCCEED";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FACEBOOK_USER_REQUEST = "GET_FACEBOOK_USER_REQUEST";
export const GET_FACEBOOK_USER_SUCCEED = "GET_FACEBOOK_USER_SUCCEED";
export const GET_FACEBOOK_USER_FAILURE = "GET_FACEBOOK_USER_FAILURE";

export const GET_PAYMENTS_REQUEST = "GET_PAYMENTS_REQUEST";
export const GET_PAYMENTS_SUCCEED = "GET_PAYMENTS_SUCCEED";
export const GET_PAYMENTS_FAILURE = "GET_PAYMENTS_FAILURE";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCEED = "CONTACT_US_SUCCEED";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

export const GET_LEAGUES_REQUEST = "GET_LEAGUES_REQUEST";
export const GET_LEAGUES_SUCCEED = "GET_LEAGUES_SUCCEED";
export const GET_LEAGUES_FAILURE = "GET_LEAGUES_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCEED = "CHANGE_PASSWORD_SUCCEED";

export const GET_GAMES_REQUEST = "GET_GAMES_REQUEST";
export const GET_GAMES_SUCCEED = "GET_GAMES_SUCCEED";
export const GET_GAMES_FAILURE = "GET_GAMES_FAILURE";

export const GET_SINGLE_GAME_REQUEST = "GET_SINGLE_GAME_REQUEST";
export const GET_SINGLE_GAME_SUCCEED = "GET_SINGLE_GAME_SUCCEED";
export const GET_SINGLE_GAME_FAILURE = "GET_SINGLE_GAME_FAILURE";

export const GET_GAME_PLAYERS_REQUEST = "GET_GAME_PLAYERS_REQUEST";
export const GET_GAME_PLAYERS_SUCCEED = "GET_GAME_PLAYERS_SUCCEED";
export const GET_GAME_PLAYERS_FAILURE = "GET_GAME_PLAYERS_FAILURE";

export const UPDATE_GAME_SUBSCRIPTION_REQUEST = "UPDATE_GAME_SUBSCRIPTION_REQUEST";
export const UPDATE_GAME_SUBSCRIPTION_SUCCEED = "UPDATE_GAME_SUBSCRIPTION_SUCCEED";
export const UPDATE_GAME_SUBSCRIPTION_FAILURE = "UPDATE_GAME_SUBSCRIPTION_FAILURE";

export const UPDATE_GAME_SUBSCRIPTION_SINGLE_REQUEST = "UPDATE_GAME_SUBSCRIPTION_SINGLE_REQUEST";
export const UPDATE_GAME_SUBSCRIPTION_SINGLE_SUCCEED = "UPDATE_GAME_SUBSCRIPTION_SINGLE_SUCCEED";
export const UPDATE_GAME_SUBSCRIPTION_SINGLE_FAILURE = "UPDATE_GAME_SUBSCRIPTION_SINGLE_FAILURE";

export const GET_RECENT_GAME_REQUEST = "GET_RECENT_GAME_REQUEST";
export const GET_RECENT_GAME_SUCCEED = "GET_RECENT_GAME_SUCCEED";
export const GET_RECENT_GAME_FAILURE = "GET_RECENT_GAME_FAILURE";

export const GET_STATS_GAME_REQUEST = "GET_STATS_GAME_REQUEST";
export const GET_STATS_GAME_SUCCEED = "GET_STATS_GAME_SUCCEED";
export const GET_STATS_GAME_FAILURE = "GET_STATS_GAME_FAILURE";

export const GET_GAME_CHART_REQUEST = "GET_GAME_CHART_REQUEST";
export const GET_GAME_CHART_SUCCEED = "GET_GAME_CHART_SUCCEED";
export const GET_GAME_CHART_FAILURE = "GET_GAME_CHART_FAILURE";

export const GET_GAME_QUARTER_REQUEST = "GET_GAME_QUARTER_REQUEST";
export const GET_GAME_QUARTER_SUCCEED = "GET_GAME_QUARTER_SUCCEED";
export const GET_GAME_QUARTER_FAILURE = "GET_GAME_QUARTER_FAILURE";

export const GET_GAME_HEAD_TO_HEAD_REQUEST = "GET_GAME_HEAD_TO_HEAD_REQUEST";
export const GET_GAME_HEAD_TO_HEAD_SUCCEED = "GET_GAME_HEAD_TO_HEAD_SUCCEED";
export const GET_GAME_HEAD_TO_HEAD_FAILURE = "GET_GAME_HEAD_TO_HEAD_FAILURE";

export const GET_GAMBLING_REQUEST = "GET_GAMBLING_REQUEST";
export const GET_GAMBLING_SUCCEED = "GET_GAMBLING_SUCCEED";
export const GET_GAMBLING_FAILURE = "GET_GAMBLING_FAILURE";

export const GET_LIVE_REQUEST = "GET_LIVE_REQUEST";
export const GET_LIVE_SUCCEED = "GET_LIVE_SUCCEED";
export const GET_LIVE_FAILURE = "GET_LIVE_FAILURE";

export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCEED = "GET_SUBSCRIPTIONS_SUCCEED";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCEED = "CANCEL_SUBSCRIPTION_SUCCEED";

export const USER_CHANGES_REQUEST = "USER_CHANGES_REQUEST";
export const USER_CHANGES_SUCCEED = "USER_CHANGES_SUCCEED";

export const CHANGE_EMAIL_REQUEST = "CHANGE_EMAIL_REQUEST";
export const CHANGE_EMAIL_SUCCEED = "CHANGE_EMAIL_SUCCEED";

export const PLANS_RESET = "PLANS_RESET";
