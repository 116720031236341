import React, { useContext } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import NewsLatterSubscription from "../../Common/NewsLatterSubscription";
import SymbolLogo from "./SymbolLogo";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
          flexDirection: "column-reverse",
          marginTop: 7,
      },
  },
  btnRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 11,
    },
  },
}));

const Footer = props => {
  const classes = useStyles();
  const { themeType } = useContext(AppContext);
  const date = new Date();

  return (
    <Box className={classes.root} {...props}>
      <Box display="flex" alignItems="center" marginTop={{xs: 8}}>
          <SymbolLogo mr={5} type='symbolOnly' />
        <Box fontSize={{ xs: 12, sm: 14 }} component="p" color="text.disabled">
         <span>Game Night Insights ©</span>  {date.getFullYear()}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" >
       <NewsLatterSubscription />

      </Box>
    </Box>
  );
};

export default Footer;
