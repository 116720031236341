import React, {useEffect} from 'react';
import {Box, TextField} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import IntlMessages from "../../utils/IntlMessages";
import Button from '@material-ui/core/Button';
import * as ROUTES from "../../route/routes";
import {useFormik} from "formik";
import {forgotPasswordSchema as validationSchema} from "../../utils/yup-schemas/authSchemas";
import {useDispatch, useSelector} from "react-redux";
import {forgotPassword} from "../../vendor/redux/actions/authActions";
import showNotificationMessage from "../../utils/notifications";

const useStyles = makeStyles(theme => ({

    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
    },
}));

export default function ForgotPassword({variant = 'default', wrapperVariant = 'default' })  {
    const classes = useStyles({ variant });
    const dispatch = useDispatch();
    const {status, message, title} = useSelector((state) => state.changePasswordReducer)

    const initialValues = {
        email: '',
    }

    const onSubmit = async (values) => {
        await dispatch(forgotPassword(values))
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
        isSubmitting,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    useEffect(() => {
        if(status) {
            showNotificationMessage(status, message, title)
        }
    }, [status])

    return(
        <>
            <Typography component="div" variant="h1" className={classes.titleRoot}>
                Forgot Password?
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <TextField
                        label={<IntlMessages id="appModule.email" />}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        name='email'
                        className={classes.textFieldRoot}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.email &&
                            Boolean(errors.email)
                        }
                        helperText={
                            errors.email
                        }
                    />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                    <Button type={'submit'} variant="contained" disabled={!isValid || isSubmitting} color="primary">
                        <IntlMessages id="appModule.resetPassword" />
                    </Button>
                    <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                        <NavLink to={ROUTES.SIGN_IN}>
                            <IntlMessages id="appModule.back" />
                        </NavLink>
                    </Box>
                </Box>
            </form>
        </>
    )
}

