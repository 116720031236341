import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeading from './TableHeading';
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import TableHead from "@material-ui/core/TableHead";

const useStyles = makeStyles(theme => ({
    actionsRoot: {
        transition: 'all 0.3s ease',
        transform: 'translateX(-110%)',
        opacity: 0,
        visibility: 'hidden',
        marginLeft: 10,
    },
    hoverAction: {
    },
    tableRowRoot: {
        position: 'relative',
        transition: 'all .2s',
        borderTop: `solid 1px ${theme.palette.borderColor.main}`,
        '&:last-child': {
            borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
        },
        '&:first-child': {
            borderBottom: `solid 1px  rgba(0, 0, 0, 0.3)`,
            backgroundColor: `rgba(0, 0, 0, 0.04)`
        },
    },
    tableCellRoot: {
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: "center",
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 14,
        letterSpacing: 0.25,
        borderBottom: '0 none',
        '&:first-child': {
            paddingLeft: 24,
        },
        '&:last-child': {
            paddingRight: 24,
        },
        '& .Cmt-avatar-more': {
            fontSize: 10,
            color: theme.palette.primary.main,
        },
    },
    tableCellSecondaryColor: {
        color: theme.palette.text.secondary,
    },
    blockRoot: {
        display: 'block',
        fontSize: 14,
    },
    tooltip: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
    winner: {
        color: "green"
    }
}));

const AwayTeamLineUpsTable = () => {
    const classes = useStyles()
    const {gameQuarter} = useSelector((state) => state.singleGameReducer);

    return (
        <>
        {
            gameQuarter &&  <div className="Cmt-table-responsive">
            <Table>
                <TableHead>
                    <TableHeading/>
                </TableHead>
                <TableBody>
                    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                            <div className={gameQuarter?.betting_line?.away_team > gameQuarter?.betting_line?.home_team && classes.winner}>{gameQuarter?.betting_line?.away_team === 'No available' ? "Not Available" : gameQuarter?.betting_line?.away_team.decimal }</div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                            <div className={gameQuarter?.quarter_winning?.away_team.win_prc > gameQuarter?.quarter_winning?.home_team.win_prc && classes.winner}>{Number(gameQuarter?.quarter_winning?.away_team.win_prc).toFixed(1)}</div>

                        </TableCell>
                    </TableRow>
                    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                            <div className={gameQuarter?.quarter_stats.away_team > gameQuarter?.quarter_stats.home_team && classes.winner}>{Number(gameQuarter?.quarter_stats.away_team).toFixed(1)}</div>
                        </TableCell>
                    </TableRow>
                    {/*<TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>*/}
                    {/*    <TableCell className={classes.tableCellRoot}>*/}
                    {/*        <div className={gameQuarter?.quarter_stats.away_team > gameQuarter?.quarter_stats.home_team && classes.winner}>{Number(gameQuarter?.quarter_stats.home_team).toFixed(1)}</div>*/}
                    {/*    </TableCell>*/}
                    {/*</TableRow>*/}
                    {/*<TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>*/}
                    {/*    <TableCell className={classes.tableCellRoot}>*/}
                    {/*        <div className={gameQuarter?.margin.away_team > gameQuarter?.margin.home_team && classes.winner}>{Number(gameQuarter?.margin.away_team).toFixed(1)}</div>*/}
                    {/*    </TableCell>*/}
                    {/*</TableRow>*/}
                </TableBody>
            </Table>
        </div>
        }
        </>
    );
};

export default AwayTeamLineUpsTable;
