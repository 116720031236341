import React from "react";
import {useDispatch} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {Box, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LoadingButton from "../PageComponents/LoadingButton";
import {useFormik} from "formik";
import {changePasswordSchema as validationSchema} from "../../utils/yup-schemas/authSchemas";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {changePassword} from "../../vendor/redux/actions/authActions";

const useStyles = makeStyles(theme => ({

    titleRoot: {
        marginBottom: 14,
        margin: 10,
        color: theme.palette.text.primary,
        justifyContent: "left",
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            fontSize: 13,
        },
    },
    formRoot: {
        alignItems: "center",
        justifyContent: "center",
    },
    googleBtn: {
        width: "100%"
    }
}));

const ChangePassword = ({variant = 'default'}) => {
    const classes = useStyles({ variant });
    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        await dispatch(changePassword(values));
    }

    const initialValues = {
        old_password: '',
        new_password: '',
        new_password_confirm: '',
    };

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
        isSubmitting,
        resetForm,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return(
        <>
            <Typography component="div" variant="h4" className={classes.titleRoot}>
                Change Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box>
                    <TextField
                        label="Old Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='old_password'
                        value={values.old_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.old_password &&
                            Boolean(errors.old_password)
                        }
                        helperText={
                            errors.old_password
                        }
                    />
                </Box>
                <Box>
                    <TextField
                        label="New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='new_password'
                        value={values.new_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.new_password &&
                            Boolean(errors.new_password)
                        }
                        helperText={
                            errors.new_password
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='new_password_confirm'
                        value={values.new_password_confirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.new_password_confirm &&
                            Boolean(errors.new_password_confirm)
                        }
                        helperText={
                            errors.new_password_confirm
                        }
                    />
                </Box>
                <Grid className={classes.formRoot}>
                <LoadingButton
                    loading={""}
                    disabled={!isValid || isSubmitting}
                    variant="contained"
                    color="primary"
                    titleText="Update Password"
                />
                </Grid>
            </form>
        </>
    );
}

export default ChangePassword;