import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtCard from '../../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import {useSelector} from "react-redux";
import RecentGameTable from "../RecentGameTable";
import LineUpsTable from "./LineUpsTable";
import {getDateDistanceFromNow, getDateMDY} from "../../../../../../utils/dateHelper";
import {parseISO} from "date-fns";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    overflowY: "auto",
    display: "flex",
    width: "100%"
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));

const RecentGameTableData = [
  {
    title: "WIN% on Back to Back",
  },
  {
    title: "WIN% 1 day rest",
  },
  {
    title: "WIN% 2 days rest",
  },
  {
    title: "WIN% 3 days rest",
  },
  {
    title: "Current Streak",
  },
]

const RecentGamesTable = () => {
  const {recentGame} = useSelector((state) => state.singleGameReducer);
  const {gameStats} = useSelector((state) => state.singleGameReducer);
  const homeTeamRecentDate = recentGame?.last?.home_team?.start_time ? getDateMDY(parseISO(recentGame?.last?.home_team?.start_time)) : null
  const homeTeamDistanceDay = recentGame?.last?.home_team?.start_time ? getDateDistanceFromNow(parseISO(recentGame?.last?.home_team?.start_time)) : null
  const awayTeamRecentDate = recentGame?.last?.away_team?.start_time ? getDateMDY(parseISO(recentGame?.last?.away_team?.start_time)) : null
  const awayTeamDistanceDay = recentGame?.last?.away_team?.start_time ? getDateDistanceFromNow(parseISO(recentGame?.last?.away_team?.start_time)) : null
  const classes = useStyles();

  return (
      <CmtCard className={classes.cardRoot}>

        <PerfectScrollbar className={classes.scrollbarRoot}>
          <CmtCardContent>
            {
              recentGame && <LineUpsTable currentStreak={recentGame?.last_games_stats?.away_team} data={gameStats?.win_lose_stats?.away_team} titleSize={'md'} title={`${awayTeamRecentDate} (${awayTeamDistanceDay})`}/>
            }
          </CmtCardContent>
          <CmtCardContent>
            <RecentGameTable data={RecentGameTableData} title="Last Game"/>
          </CmtCardContent>
          <CmtCardContent>
            {
              recentGame &&  <LineUpsTable currentStreak={recentGame?.last_games_stats?.home_team} data={gameStats?.win_lose_stats?.home_team} titleSize={'md'} title={`${homeTeamRecentDate} (${homeTeamDistanceDay})`}/>
            }
          </CmtCardContent>
        </PerfectScrollbar>
      </CmtCard>
  );
};

export default RecentGamesTable;
