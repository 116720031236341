import {RESET_STATE, SIGN_UP_FAILURE, SIGN_UP_REQUEST, SIGN_UP_SUCCEED} from "../consts";

const initialState = {
    signUpStatus: null,
    message: "",
    data: null,
    authProvider: null
};

const signUpReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case SIGN_UP_REQUEST:
            return {
                ...state,
                signUpStatus: "pending",
                message: "",
                authProvider: null,
            };
        case SIGN_UP_SUCCEED:
            return {
                ...state,
                signUpStatus: "success",
                title: "Sign up success",
                authProvider: action?.payload?.data?.user?.auth_provider
            };
        case SIGN_UP_FAILURE:
            return {
                ...state,
                signUpStatus: "error",
                message: "There is already registered user by the provided email address or something went wrong.",
                title: "Sign up failure",
            };
        case RESET_STATE:
            return {
                ...state,
                signUpStatus: null,
                message: null,
                title: null
            }
        default:
            return state;
    }
}

export default signUpReducer;