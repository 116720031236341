import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import {Typography} from "@mui/material";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {setNewEmail, userStateReset} from "../../vendor/redux/actions/userActions";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../route/routes";

const useStyles = makeStyles(theme => ({
    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
    },
}));

const SetEmail = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user, status} = useSelector(state => state.signInReducer);

    const onConfirm = () => dispatch(setNewEmail({confirmation: true}));
    const onDecline = () => dispatch(setNewEmail({confirmation: false}));

    useEffect(() => {if(status === "success") {history.push(ROUTES.HOME); dispatch(userStateReset)}},[status]);
    return(
        <>
            <Typography component="div" variant="h5" className={classes.titleRoot}>
                Set Email
            </Typography>
            <Box mt={2}>
                <Typography component="div" variant="p" className={classes.titleRoot}>
                    From {user?.email} to
                </Typography>
                <Box mt={5}>
                    <Button onClick={onDecline} color="primary" variant="outlined">
                        Decline
                    </Button>
                    <Button onClick={onConfirm} color="primary" variant="contained">
                        Confirm
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default SetEmail;