import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import GameChartHeading from './GameChartHeading';
import TableItem from './TableItem';

const RecentGameTableData = [
    {
        title: "POINTS",
    },
    {
        title: "REBOUNDS",
    },
    {
        title: "ASSISTS",
    },
    {
        title: "STEALS",
    },
    {
        title: "TURNOVERS",
    },
    {
        title: "FG %",
    },
    {
        title: "3 POINTS FG %",
    },
    {
        title: "FREE THROW %",
    },
    {
        title: "BLOCKS",
    },
]

const GameChartTable = ({title, titleSize }) => {
  return (
    <div className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <GameChartHeading titleSize={titleSize} title={title} />
        </TableHead>
        <TableBody>
          {RecentGameTableData.map((row, index) => (
            <TableItem key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default GameChartTable;
