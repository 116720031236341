import React from 'react';
import clsx from 'clsx';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux";
import Error from '@material-ui/icons/Error'


const useStyles = makeStyles(theme => ({
  actionsRoot: {
    transition: 'all 0.3s ease',
    transform: 'translateX(-110%)',
    opacity: 0,
    visibility: 'hidden',
    marginLeft: 10,
  },
  hoverAction: {
    '&:hover $actionsRoot': {
      transform: 'translateX(0%)',
      opacity: 1,
      visibility: 'visible',
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    paddingTop: 5,
    display: "flex",
    justifyContent: "center",
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {

      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  win: {
    color: "green",
  },
  lost: {
    color: "red"
  },
  errorMessage : {
    color: "#fea621",
    display: "flex",
    alignItems: "center"
  }
}));

const TableItem = ({ stats, currentStreak }) => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state) => state.signInReducer);

  return (
      <>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            {
              !isLoggedIn ?
                  <div className={classes.errorMessage}>
                    <Error style={{marginRight: "6px", fontSize: "20px"}}/>
                    Log in to see the data.
                  </div> :
                  <div style={{marginRight: "6px"}}>
                    <span className={classes.win}>W</span> {Number(stats?.back_to_back.win_prc).toFixed(1)}
                  </div>
            }
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            {
              !isLoggedIn ?
                  <div className={classes.errorMessage}>
                    <Error style={{marginRight: "6px", fontSize: "20px"}}/>
                    Log in to see the data.
                  </div> :
                  <div style={{marginRight: "6px"}}>
                    <span className={classes.win}>W</span> {Number(stats?.one_day.win_prc).toFixed(1)}
                  </div>
            }
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            {
              !isLoggedIn ?
                  <div className={classes.errorMessage}>
                    <Error style={{marginRight: "6px", fontSize: "20px"}}/>
                    Log in to see the data.
                  </div> :
                  <div style={{marginRight: "6px"}}>
                    <span className={classes.win}>W</span> {Number(stats?.two_days.win_prc).toFixed(1)}
                  </div>
            }
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            {
              !isLoggedIn ?
                  <div className={classes.errorMessage}>
                    <Error style={{marginRight: "6px", fontSize: "20px"}}/>
                    Log in to see the data.
                  </div> :
                  <div style={{marginRight: "6px"}}><span
                      className={classes.win}>W</span> {Number(stats?.three_days.win_prc).toFixed(1)}</div>
            }
          </TableCell>
        </TableRow>
        <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
          <TableCell className={classes.tableCellRoot}>
            <>
              <div style={{marginRight: "6px"}}>
                <span className={currentStreak?.type === 'win' ? classes.win : classes.lost}>{currentStreak?.type === 'win' ? "W" : "L"} </span>
                {currentStreak?.count }
              </div>
            </>
          </TableCell>
        </TableRow>
      </>
  );
};

export default TableItem;
