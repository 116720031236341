import React, { useEffect, useState } from 'react';
import NewsList from './ScoringList';
import {Box} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {getQuarterCharts} from "../../../../../vendor/redux/actions/gamesActions";

export const quarter = {

  quarterTabs: [
    {
      id: 1,
      category: 'first-half',
    },
    {
      id: 2,
      category: 'second-half',
    },
    {
      id: 3,
      category: 1,
    },
    {
      id: 4,
      category: 2,
    },
    {
      id: 5,
      category: 3,
    },
    {
      id: 6,
      category: 4,
    },
    {
      id: 7,
      category: 7
    },
  ],
};

const { quarterTabs } = quarter;

const QuarterData = ({gameId}) => {
  const [categoryData, setCategoryData] = useState(quarterTabs);
  const [currentTab, setCurrentTab] = useState(1);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();


  useEffect(() => {
    setCategoryData(currentTab ? quarterTabs.filter(item => item.category === currentTab) : quarterTabs);
  }, [currentTab]);

  useEffect(() => {
    let queryParam;
    if(currentTab == 'first-half') {
      queryParam = 'quarter=1&quarter=2'
    } else if(currentTab == 'second-half') {
      queryParam = 'quarter=3&quarter=4'
    } else  queryParam = `quarter=${currentTab}`
    dispatch(getQuarterCharts(gameId, queryParam))
  }, [currentTab] )


  const getFilteredData = () => {
    if (searchText) {
      return categoryData.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase()));
    } else return categoryData;
  };
  const data = getFilteredData();

  return (
      <React.Fragment>
        <Box>
          <NewsList
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              data={data}
          />
        </Box>
      </React.Fragment>
  );
};

export default QuarterData;
