import requests from "./base";

const Game = {
    getLeagues: () => requests.get(`api/game/get-leagues/`),
    getGames: (startDate,token) => requests.get(`api/game/nba/get-games/?start_time=${startDate}`),
    getGameDetails: (gameId) => requests.get(`api/game/${gameId}/`),
    getGamePlayers: (gameId) => requests.get(`api/game/${gameId}/players/`),
    updateGameSubscription: (gameId, data) => requests.post(`api/game/${gameId}/user-subscribtion/`, data),
    getRecentGame: (gameId) => requests.get(`api/game/${gameId}/recent/`),
    getsStatsGame: (gameId) => requests.get(`api/game/${gameId}/recent-stats/`),
    getGameCharts: (gameId) => requests.get(`api/game/${gameId}/charts/`),
    getGameQuarter: (gameId, quarter) => requests.get(`api/game/${gameId}/quarter-charts/?${quarter}`),
    getGameHeadToHead: (gameId) => requests.get(`api/game/${gameId}/head-to-head/`),
    getLiveData: (gameId) => requests.get(`api/game/${gameId}/live/`),
};

export default Game;