export const getAccessToken = () => localStorage.getItem("jwt-access-token");

export const getRefreshToken = () => localStorage.getItem("jwt-refresh-token");

export const setToken = async (access, refresh) => {
    localStorage.setItem("jwt-access-token", access);
    localStorage.setItem("jwt-refresh-token", refresh);
    await null;
};

export const clearStorage = () => localStorage.clear();