import {Backdrop, CircularProgress} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import React from "react";

const useStyles = makeStyles(theme => ({
    circularProgressRoot: {
        textAlign: "center",
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "30px",
        marginTop: "30px",
    },
}));

const DataLoader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.circularProgressRoot}>
            <CircularProgress size={40} />
        </Box>
    );
}

export default DataLoader;