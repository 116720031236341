import * as Yup from "yup";
import {differenceInYears} from "date-fns";
import errorMessages from "./errorMessages";

/**
 * @description - Validation schema for sign-up form.
 */

export const signUpSchema = function () {
    const validationMessages = errorMessages ('validation');
    return Yup.object().shape({
        first_name: Yup.string()
            .required(validationMessages.required),
        last_name: Yup.string()
            .required(validationMessages.required),
        email: Yup.string()
            .email(validationMessages.email)
            .required(validationMessages.required),
        dob: Yup.date()
            .nullable()
            .required(validationMessages.required)
            .test("dob", validationMessages.age, function (value) {
                return differenceInYears(new Date(), new Date(value)) >= 18;
            }),
    });
};

/**
 * @description - Validation schema for sign-in form.
 */

export const signInSchema = function () {
    const validationMessages = errorMessages ('validation');
    return Yup.object().shape({
        email: Yup.string()
            .email(validationMessages.email)
            .required(validationMessages.required),
        password: Yup.string()
            .matches(/^(?=.*\d{2,})(?=.*\w)(?=.*[A-Z]).{6,}$/,
                validationMessages.passwordMinimals)
            .required("This field is required"),
    });
};


/**
 * @description - Validation schema for set-password form.
 */

export const setPasswordSchema = function () {
    const validationMessages = errorMessages ('validation');

    return Yup.object().shape({

        password: Yup.string()
            .matches(/^(?=.*\d{2,})(?=.*\w)(?=.*[A-Z]).{6,}$/,
                validationMessages.passwordMinimals)
            .required("This field is required"),
        password_confirm: Yup.string()
            .matches(/^(?=.*\d{2,})(?=.*\w)(?=.*[A-Z]).{6,}$/,
                validationMessages.passwordMinimals)
            .required("This field is required")
            .oneOf([Yup.ref("password")], "The passwords do not match"),
    });
};

/**
 * @description - Validation schema for forgot-password form.
 */

export const forgotPasswordSchema = function () {
    const validationMessages = errorMessages ('validation');
    return Yup.object().shape({
        email: Yup.string()
            .email(validationMessages.email)
            .required(validationMessages.required),
    });
};

/**
 * @description - Validation schema for change-password form.
 */

export const changePasswordSchema = function () {
    const validationMessages = errorMessages ('validation');

    return Yup.object().shape({
        old_password: Yup.string()
            .matches(/^(?=.*\d{2,})(?=.*\w)(?=.*[A-Z]).{6,}$/,
                validationMessages.passwordMinimals)
            .required("This field is required"),
        new_password: Yup.string()
            .matches(/^(?=.*\d{2,})(?=.*\w)(?=.*[A-Z]).{6,}$/,
                validationMessages.passwordMinimals)
            .required("This field is required"),
        new_password_confirm: Yup.string()
            .matches(/^(?=.*\d{2,})(?=.*\w)(?=.*[A-Z]).{6,}$/,
                validationMessages.passwordMinimals)
            .required("This field is required")
            .oneOf([Yup.ref("new_password")], "The passwords do not match"),
    });
};

/**
 * @description - Validation schema for contact-us form.
 */

export const contactUsSchema = function () {
    const validationMessages = errorMessages ('validation');
    return Yup.object().shape({
        first_name: Yup.string()
            .required(validationMessages.required),
        last_name: Yup.string()
            .required(validationMessages.required),
        email: Yup.string()
            .email(validationMessages.email)
            .required(validationMessages.required),
        subject: Yup.string()
            .required(validationMessages.required),
        message: Yup.string()
            .required(validationMessages.required),
    });
};

/**
 * @description - Validation schema for user changes form.
 */

export const userChangesSchema = function () {
    const validationMessages = errorMessages ('validation');
    return Yup.object().shape({
        first_name: Yup.string()
            .required(validationMessages.required),
        last_name: Yup.string()
            .required(validationMessages.required),
        email: Yup.string()
            .email(validationMessages.email)
            .required(validationMessages.required),
        dob: Yup.date()
            .required(validationMessages.required),
    });
};