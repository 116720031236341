import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
    textCapitalize: {
      textTransform: "capitalize"
    }
}));

const InjuryListRow = ({ row }) => {
  const classes = useStyles();

  return (
    <TableRow
      hover
      tabIndex={-1}>
      <TableCell padding="checkbox">
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
                {row.first_name} {row.last_name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.primary_position ? row.primary_position : '-'}</TableCell>
      <TableCell className={classes.textCapitalize}>{row.current_injury_description ? row.current_injury_description : '-'}</TableCell>
      <TableCell>{row.current_injury_playing_probability ? row.current_injury_playing_probability : '-'}</TableCell>
    </TableRow>
  );
};

export default React.memo(InjuryListRow);
