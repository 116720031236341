import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import TableHeading from "../../../HeadToHead/RegularSeasonSeriesTable/TableHeading";

const useStyles = makeStyles(theme => ({
    actionsRoot: {
        transition: 'all 0.3s ease',
        transform: 'translateX(-110%)',
        opacity: 0,
        visibility: 'hidden',
        marginLeft: 10,
    },
    hoverAction: {
        '&:hover $actionsRoot': {
            transform: 'translateX(0%)',
            opacity: 1,
            visibility: 'visible',
        },
    },
    tableRowRoot: {
        position: 'relative',
        transition: 'all .2s',
        borderTop: `solid 1px ${theme.palette.borderColor.main}`,
        '&:last-child': {
            borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
        },
    },
    tableCellRoot: {
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: "center",
        paddingTop: 5,
        display: "flex",
        justifyContent: "center",
        paddingBottom: 5,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.text.primary,
        borderBottom: '0 none',
        '&:first-child': {
            paddingLeft: 24,
        },
        '&:last-child': {

            paddingRight: 24,
        },
        '& .Cmt-avatar-more': {
            fontSize: 10,
            color: theme.palette.primary.main,
        },
    },
    tableCellSecondaryColor: {
        color: theme.palette.text.secondary,
    },
    blockRoot: {
        display: 'block',
        fontSize: 14,
    },
    winner: {
        color: "green"
    }
}));

const AwayTeamHistoryData = ({data, title}) => {
    const classes = useStyles();
    const awayTeamFinalScore = data?.game?.away_score_total
    const awayTeamMoneyLine = data?.betting?.money_line?.moneyLine?.awayLine?.decimal
    const awayTeamPointSpread= data?.betting?.point_spread?.pointSpread?.awaySpread
    const awayTeamOverUnder= data.betting?.over_under?.overUnder?.overUnder
    const awayTeamOverLineText= "O"
    const homeTeamFinalScore = data?.game?.home_score_total
    const homeTeamMoneyLine = data?.betting?.money_line?.moneyLine?.homeLine?.decimal
    const homeTeamPointSpread= data?.betting?.point_spread?.pointSpread?.homeSpread
    const homeTeamOverUnder= data.betting?.over_under?.overUnder?.overUnder

    return (
        <div className="Cmt-table-responsive">
            <Table>
                <TableHead>
                    <TableHeading title={title} />
                </TableHead>
                <TableBody>
                    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                            <div style={{marginRight: "6px"}} className={awayTeamFinalScore > homeTeamFinalScore && classes.winner}>
                                {awayTeamFinalScore ? awayTeamFinalScore : "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                            <div style={{marginRight: "6px"}} className={awayTeamMoneyLine > homeTeamMoneyLine && classes.winner}>
                                {awayTeamMoneyLine ? awayTeamMoneyLine : "-"  }
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                            <div style={{marginRight: "6px"}} className={awayTeamPointSpread > homeTeamPointSpread && classes.winner}>
                                {awayTeamPointSpread ? awayTeamPointSpread : "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={clsx(classes.hoverAction, classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                            <div style={{marginRight: "6px"}} className={awayTeamFinalScore > homeTeamFinalScore && awayTeamOverUnder && classes.winner}>
                                {awayTeamOverLineText} {awayTeamOverUnder ? awayTeamOverUnder : "-"}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

export default AwayTeamHistoryData;
