import React from 'react';
import {NotificationManager} from "react-notifications";

export default function showNotificationMessage (type, notification, title) {
    switch (type) {
        case 'success':
            NotificationManager.success(notification, title);
            break;
        case 'error':
            NotificationManager.error(notification, title);
            break;
    }
}