import { combineReducers } from "redux";
import signUpReducer from "./signUpReducer";
import verifyAccountReducer from "./verifyAcoountReducer";
import setPasswordReducer from "./setPasswordReducer";
import signInReducer from "./signInReducer";
import userReducer from "./userReducer";
import changePasswordReducer from "./changePasswordReducer";
import plansReducer from "./plansReducer";
import leaguesReducer from "./leaguesReducer";
import gamesReducer from "./gamesReducer";
import singleGameReducer from "./singleGameReducer";

export default combineReducers({
  signUpReducer,
  verifyAccountReducer,
  setPasswordReducer,
  signInReducer,
  changePasswordReducer,
  userReducer,
  plansReducer,
  gamesReducer,
  leaguesReducer,
  singleGameReducer
});