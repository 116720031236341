import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import RecentGameHeading from './RecentGameHeading';
import TableItem from './TableItem';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    highlightedText: {
        fontWeight: 600,
        textAlign: "center",
        textDecoration: "underline"
    },
    textSmall: {
        fontSize: "12px",
        fontStyle: "italic",
        textAlign: "center"
    },
    injuriesList: {
        listStyleType: "none",
        display: "inline",
        paddingLeft: "0",

        '& li' : {
            display: "inline",
        }
    }
}));



const RecentGameTable = ({ data }) => {
    const classes = useStyles()
    return (
        <div className="Cmt-table-responsive">
            <Table>
                <TableHead>
                    <RecentGameHeading  title={" - "} />
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableItem key={index} row={row} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default RecentGameTable;
