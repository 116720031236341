import React from 'react';
import {Box} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import BreadcrumbShower from "../../components/Breadcrumbs/Breadcrumbs";

const useStyles = makeStyles(theme => ({
    authWrap: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: 20,
        [theme.breakpoints.up('sm')]: {
            padding: 40,
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
        },
    },
    authContent: {
        padding: 30,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            order: 1,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 50,
        },
    },

    authCard: {
        position: 'relative',
        zIndex: 3,
        maxWidth: '630px',
        width: '100%',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: props => (props.variant === 'default' ? '700px' : '850px'),
        },
    },
}));
export default function AuthLayout({children, variant}) {
    const classes = useStyles({ variant });
    return(
        <>
            <Box style={{width: "100%", display: "flex"}}>
                <Box className={classes.authWrap}>
                        <Box className={classes.authCard}>
                            <Box className={classes.authContent}>
                                {children}
                            </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
