import React from "react"
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GameChartTeamTable from "./GameCharts/GameChartsTable/GameChartTeamData";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import {Divider} from "material-ui-core";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import QuarterData from "./GameCharts/QuarterScoring";

const useStyles = makeStyles(() => ({
    profileMainContent: {
        '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
}));

export default function GameCharts({gameId}) {
    const classes = useStyles();

    return(
        <>

            <Grid item xs={12} className={classes.profileMainContent}>
                <Box mb={6}>
                    <CmtCard>
                        <CmtCardHeader style={{textAlign: "center"}} title="Season Averages Per Game" />
                        <Divider/>
                        <CmtCardContent>
                            <GameChartTeamTable gameId={gameId}/>
                        </CmtCardContent>
                    </CmtCard>
                </Box>
                <Box>
                    <QuarterData gameId={gameId}/>
                </Box>
            </Grid>
        </>
    )
}