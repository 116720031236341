import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import LineUpsTable from './LineUpsTable';
import {useSelector} from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import {formatInTimeZone} from "date-fns-tz";
import {format, parseISO} from "date-fns";
import {isDateAfter} from "../../../../../utils/dateHelper";
import {indexOf} from "@amcharts/amcharts4/.internal/core/utils/Array";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: "space-between",

    '& .Cmt-card-content': {
      padding: 0,
      width: "100%",
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 420,
    overflowY: "auto",
    display: "flex",
    width: "100%"
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));

const StartingLineUpsTable = () => {
  const {gameDetails} = useSelector((state) => state.singleGameReducer);
  const homeTeamPlayers = useSelector((state) => state.singleGameReducer?.gamePlayers?.home_team_player);
  const awayTeamPlayers = useSelector((state) => state.singleGameReducer?.gamePlayers?.away_team_player);

  //  For getting starter position players
  const startingHomeTeamPlayers = homeTeamPlayers.filter(player => {
    return player.lineup_position?.startsWith('Starter')
  })
  const sortedStartingHomePlayers = startingHomeTeamPlayers.sort((a, b) => a.lineup_position?.localeCompare(b.lineup_position))
  const startingAwayTeamPlayers = awayTeamPlayers.filter(player => {
    return player.lineup_position?.startsWith('Starter')
  })
  const sortedStartingAwayPlayers = startingAwayTeamPlayers.sort((a, b) => a.lineup_position?.localeCompare(b.lineup_position))

  //  For getting bench position players

  const benchHomeTeamPlayers = homeTeamPlayers.filter(player => {
    return player.lineup_position?.startsWith('Bench')
  })
  const sortedBenchHomePlayers = benchHomeTeamPlayers.sort((a, b) => a.lineup_position?.localeCompare(b.lineup_position))
  const startingBenchTeamPlayers = awayTeamPlayers.filter(player => {
    return player.lineup_position?.startsWith('Bench')
  })
  const sortedBenchAwayPlayers = startingBenchTeamPlayers.sort((a, b) => a.lineup_position?.localeCompare(b.lineup_position))

if(startingAwayTeamPlayers.length > 0 && startingHomeTeamPlayers > 0) {
  // Position where from the element is
  // going to move
  let x = 4;

  // Position at which element is to be
  // moved here
  let pos = 2;

  // Store the moved element in a temp
  // variable
  let tempHome = sortedStartingHomePlayers[x];
  let tempAway = sortedStartingAwayPlayers[x];

  // shift elements forward
  let i;
  for (i = x; i >= pos; i--)
  {
    sortedStartingAwayPlayers[i] = sortedStartingAwayPlayers[i - 1];
    sortedStartingHomePlayers[i] = sortedStartingHomePlayers[i - 1];
  }

  // Insert moved element at position
  sortedStartingAwayPlayers[pos] = tempAway;
  sortedStartingHomePlayers[pos] = tempHome;
}

  const pastGame = isDateAfter(gameDetails.game.start_time)

  const classes = useStyles();
  return (
      <>
        {
        !startingAwayTeamPlayers.length && !startingHomeTeamPlayers.length && !pastGame &&
            <MuiAlert elevation={6} variant="filled" severity="warning" style={{margin: "8px"}}>
              The lineup will be available shortly before the game.
            </MuiAlert>
      }
        {
          sortedStartingAwayPlayers.length > 0 && startingHomeTeamPlayers.length > 0 && <CmtCard className={classes.cardRoot}>
            <PerfectScrollbar className={classes.scrollbarRoot}>
              <CmtCardContent>
                <LineUpsTable data={sortedStartingAwayPlayers} subtitle={'Starters'}
                              title={`${gameDetails?.game.away_team.city} ${gameDetails?.game.away_team.name}`}/>
                <LineUpsTable data={sortedBenchAwayPlayers} subtitle={'Bench'}/>
              </CmtCardContent>
              <CmtCardContent>
                <LineUpsTable data={sortedStartingHomePlayers} subtitle={'Starters'}
                              title={`${gameDetails?.game.home_team.city} ${gameDetails?.game.home_team.name}`}/>
                <LineUpsTable data={sortedBenchHomePlayers} subtitle={'Bench'}/>
              </CmtCardContent>
            </PerfectScrollbar>
          </CmtCard>
        }
      {
        gameDetails.game?.played_status === "COMPLETED" && !sortedStartingAwayPlayers.length && !sortedStartingHomePlayers.length || pastGame && gameDetails.game.played_status === 'UNPLAYED' && !sortedStartingAwayPlayers.length && !sortedStartingHomePlayers.length &&
         <p style={{textAlign: "center"}}>No data to show</p>
      }
      </>
  );
};

export default StartingLineUpsTable;
