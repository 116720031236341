import React from 'react';
import Box from '@material-ui/core/Box';
import CmtAdvCard from '../../../@coremat/CmtAdvCard';
import CmtAdvCardContent from '../../../@coremat/CmtAdvCard/CmtAdvCardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {NavLink} from "react-router-dom";
import * as ROUTES from "../../../route/routes";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    color: theme.palette.common.white,
    padding: 16,
    marginBottom: 16,
    minHeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(16, 14, 58, 0.55)'
    },
  },
  subTitleRoot: {

    marginBottom: 10,
    letterSpacing: 1.2,
    maxWidth: "770px"
  },
  subTitleLg: {
    fontSize: "32px!important",
  },
 subTitleSm: {
    fontSize: "24px!important",
  },
  startNow: {
    display: "inline-block",
    padding: "6px 16px",
    minWidth: "64px",
    boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    lineHeight: "1.75",
    color: "#fff",
    backgroundColor: "#fda521",
    borderRadius: "4px",
    fontSize: "16px",
    transition: "all 0.2s",
    '&:hover' : {
      opacity: '0.8'
    }
  }
}));

const BannerAndText = ({bgImage, text, button, fontSize}) => {
  const classes = useStyles();
  return (
      <CmtAdvCard
          className={classes.cardRoot}
          backgroundColor={['#03DAC5 -18.96%', '#6200EE 108.17%']}
          backgroundImage={bgImage}
      >
        <CmtAdvCardContent
            subTitle={text}
            subTitleProps={{
              variant: 'h1',
              component: 'div',
              className:
                fontSize === 'subTitleLg' ? classes.subTitleLg :  classes.subTitleSm

            }}

            extraContent={
              <Box mt={5} mx={-2} display="flex" alignItems="center">
                {button && <Box px={2}>
                  <NavLink
                      to={ROUTES.INSIGHTS}
                      className={classes.startNow}
                  >
                    Start Now
                  </NavLink>
                </Box>}
              </Box>
            }
            alignCenter
        />
      </CmtAdvCard>
  );
};

export default BannerAndText;
