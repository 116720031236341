import React from 'react';
import {useSelector} from "react-redux";
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles';
import CmtImage from '../../../@coremat/CmtImage';
import GameCard from "../GameCard";

const tabs = [
  { id: 1, title: 'Teams News & Rosters', slug: 'rosters' },
  { id: 2, title: 'Game Charts', slug: 'gameCharts' },
  { id: 3, title: 'Recent Games', slug: 'recentGames' },
  { id: 4, title: 'Head to Head', slug: 'headToHead' },
  { id: 5, title: 'Gambling Central', slug: 'gamblingCentral' },
];

const useStyles = makeStyles(theme => ({
  headerRoot: {
    margin: '-30px -15px 0 -15px',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 30,
    height : "470px",
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 56,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: -50,
      marginRight: -50,
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: -65,
      marginRight: -65,
      paddingLeft: 65,
      paddingRight: 65,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: -88,
      marginRight: -88,
      paddingLeft: 88,
      paddingRight: 88,
    },
  },
  headerBgImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '530px',
    zIndex: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "#131238",

    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  headerContent: {
    position: 'relative',
    zIndex: 3,
  },
  titleRoot: {
    color: theme.palette.common.white,
    marginBottom: 4,
  },
  subTitleRoot: {
    color: alpha(theme.palette.common.white, 0.74),
  },
  followerList: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    marginRight: -15,
    [theme.breakpoints.up('md')]: {
      marginLeft: -24,
      marginRight: -24,
    },
  },
  followerListItem: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    cursor: 'pointer',
    color: alpha(theme.palette.common.white, 0.74),
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '&:not(:first-child)': {
      borderLeft: `solid 1px ${alpha(theme.palette.common.white, 0.38)}`,
    },
  },
  followerListTitle: {
    color: theme.palette.common.white,
    marginBottom: 3,
  },
  tabsList: {
    position: 'relative',
    minHeight: 10,
    '& .MuiTabs-indicator': {
      backgroundColor: alpha(theme.palette.common.white, 0.4),
    },
  },
  tabItem: {
    maxWidth: 'none',
    textTransform: "uppercase",
    minWidth: 10,
    minHeight: 10,
    padding: '5px 10px',
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const GameSingleHeader = ({ tabValue, handleTabChange }) => {
  const classes = useStyles();
  const {gameDetails} = useSelector((state) => state.singleGameReducer);
  const {game} = gameDetails

  return (
      <Box className={classes.headerRoot}>
        <Box className={classes.headerBgImg}>
          <CmtImage src={'/images/profile-bg-img.png'} />
        </Box>
        <Box className={classes.headerContent}>
          <Box alignItems="center" mb={7}>
            <GameCard single={true} game={game}/>
          </Box>
          <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
            <Tabs className={classes.tabsList} value={tabValue} onChange={handleTabChange} aria-label="profile tabs">
              {tabs.map((item, index) => {
                return (
                    <Tab
                        className={classes.tabItem}
                        key={index}
                        value={item.slug}
                        label={item.title}
                        style={{ color: 'white' }}
                    />
                );
              })}
            </Tabs>
          </Box>
        </Box>
      </Box>
  );
};

export default GameSingleHeader;
