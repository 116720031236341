import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {contactUsSchema as validationSchema} from "../../utils/yup-schemas/authSchemas";
import Typography from "@material-ui/core/Typography";
import {Box, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {alpha, makeStyles} from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import FilePicker from "../../components/Common/FilePicker";
import {contactUs} from "../../vendor/redux/actions/userActions";
import showNotificationMessage from "../../utils/notifications";
import {SITE_KEY_FOR_CAPTCHA} from "../../constants/keys";
import LoadingButton from "../../components/PageComponents/LoadingButton";

const useStyles = makeStyles(theme => ({
    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    formcontrolLabelRoot: {
        '& .MuiFormControlLabel-label': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
    },
}));

export default function ContactUs({ variant = 'default', wrapperVariant = 'default' })  {
    const classes = useStyles({ variant });
    const dispatch = useDispatch();
    const {status, contactStatus, message, title} = useSelector((state) => state.userReducer);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [completeValues, setCompleteValues] = useState("");
    const [text, setText] = useState({message: "Attach files", color: ""})
    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        subject: '',
        message: '',
    }

    const handleAddAttachments = files => {
        setCompleteValues({...values, attached_file: files[0].file});
        setText({message: `Attached files: ${files[0].file.name}`, color: 'green'});
    };
    const handleRejectionAttachment = files => {
        setCompleteValues({...values, attached_file: null});
        setText({message: `Rejection: ${files[0].file.errors[0].message}`, color: 'red'});
    }

    const formDataCollector = values => {
        const formData = new FormData();
        Object.keys(values).map(key => formData.append(key, values[key]));
        return formData;
    }

    const onSubmit = async () => {
        await dispatch(contactUs(formDataCollector(completeValues)));
        resetForm();
        setCompleteValues("");
        setText({message: "Attach files", color: ""});
    }

    const handleChangeCaptcha = () => setSubmitDisabled(!submitDisabled);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
        isSubmitting,
        resetForm,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    useEffect(() => {
        showNotificationMessage(status, message, title)

    }, [status])

    return(
        <>
            <Typography component="div" variant="h1" className={classes.titleRoot}>
                Contact us
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box>
                    <TextField
                        label="First Name"
                        fullWidth
                        margin="normal"
                        required
                        variant="outlined"
                        className={classes.textFieldRoot}
                        name='first_name'
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.first_name &&
                            Boolean(errors.first_name)
                        }
                        helperText={
                            errors.first_name
                        }
                    />

                </Box>
                <Box>
                    <TextField
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='last_name'
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.last_name &&
                            Boolean(errors.last_name)
                        }
                        helperText={
                            errors.last_name
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.email &&
                            Boolean(errors.email)
                        }
                        helperText={
                            errors.email
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Subject"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        className={classes.textFieldRoot}
                        name='subject'
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.subject &&
                            Boolean(errors.subject)
                        }
                        helperText={
                            errors.subject
                        }
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Message"
                        multiline
                        maxRows={5}
                        minRows={3}
                        required
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.message &&
                            Boolean(errors.message)
                        }
                        helperText={
                            errors.message
                        }
                    />
                </Box>
                <FilePicker
                    onAddAttachments={handleAddAttachments}
                    onRejectAttachment={handleRejectionAttachment}
                    text={text}
                />
                <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    data-expired-callback="handleChangeCaptcha"
                    sitekey={SITE_KEY_FOR_CAPTCHA}
                    onChange={handleChangeCaptcha}
                />
                <Box mb={5} mt={5}>
                    <LoadingButton
                        loading={contactStatus === "pending"}
                        disabled={!isValid || isSubmitting || submitDisabled || !completeValues.attached_file}
                        variant="contained"
                        color="primary"
                        titleText="Submit"
                    />
                </Box>
            </form>
        </>
    )
}