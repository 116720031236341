import {
    UPDATE_GAME_SUBSCRIPTION_REQUEST,
    UPDATE_GAME_SUBSCRIPTION_SUCCEED,
    GET_GAMES_REQUEST,
    GET_GAMES_SUCCEED, GET_GAMES_FAILURE
} from "../consts";

const initialState = {
    status: null,
    updateSubscriptionStatus: null,
    games: null,
    newsfeed: []
}


const gamesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_GAMES_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case  GET_GAMES_SUCCEED:
            return {
                ...state,
                status: "success",
                games: action.payload.games,
                newsfeed: [action.payload.yahoo, action.payload.espn, action.payload.cbs_sports]
            };
        case  GET_GAMES_FAILURE:
            return {
                status: "error",
            };
        case UPDATE_GAME_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                updateSubscriptionStatus: 'pending',
            };
        case  UPDATE_GAME_SUBSCRIPTION_SUCCEED:
            return {
                ...state,
                updateSubscriptionStatus: "success",
                games: state.games.map((game) => {
                    if(game.api_game_id === action.payload.game) {
                        return {...game, subscription_status: action.payload.status}
                    }
                     return game
                })
            };
        default:
            return state;
    }
}

export default gamesReducer;