import {
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCEED,
    GET_PAYMENTS_REQUEST,
    GET_PAYMENTS_SUCCEED,
    PLANS_RESET,
} from "../consts";

const initialState = {
    status: null,
    plans: null,
    message: null,
}

const plansReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_PAYMENTS_REQUEST:
            return {
                ...state,
                status: 'pending',
                plans: null
            };
        case GET_PAYMENTS_SUCCEED:
            return {
                ...state,
                status: "success",
                plans: action.payload
            };
        case CANCEL_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                status: 'pending',
            };
        case CANCEL_SUBSCRIPTION_SUCCEED:
            return {
                ...state,
                status: "success",
            };
        case PLANS_RESET:
            return {
                ...state,
                status: null,
            };
        default:
            return state;
    }
}

export default plansReducer;