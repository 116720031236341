import React, {useEffect, useState} from 'react'
import CmtAdvCard from "../../../@coremat/CmtAdvCard";
import {Box} from "@material-ui/core";
import {CmtSubTitle} from "../../../@coremat/CmtTypography";
import CmtAvatar from "../../../@coremat/CmtAvatar";
import {Typography} from "material-ui-core";
import {AccessTime, LocationOn} from "@material-ui/icons";
import makeStyles from "material-ui-core/styles/makeStyles";
import { formatInTimeZone } from 'date-fns-tz'
import {format, parseISO} from "date-fns";
import ManageSubscription from "../Games/ManageSubscription";
import {useDispatch, useSelector} from "react-redux";
import {getGameDetails, getGamePlayers, getLiveData} from "../../../vendor/redux/actions/gamesActions";

const useStyles = makeStyles(theme => ({
    singleGame: {
        display: "flex",
        flexWrap: "wrap",
        minHeight: "200px",
        marginBottom: "12px",
        transition: "all 0.2s",
        overflow: "hidden",
        borderRadius: "0",

        "&:hover" : {
            opacity: '0.9'
        }
    },
    contentTop: {
        padding: "24px 0px",
        width: "100%",
        backgroundColor: "rgb(16, 14, 58)",

        "& .subTitle": {
            fontSize: "16px",
            color: "#ffffff",
            width: "50%",
            textAlign: "center",
            maxWidth: "250px",
            lineHeight: "1.25",
            marginTop: "16px"
        },
    },
    info : {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0 24px",
        justifyContent: "space-around",
        marginBottom: "20px"
    },
    contentBottom: {
        padding: "12px 8px",
        width: "100%",
        position: 'relative',
        minHeight: "80px",

        '& .content' : {
            marginTop: "50px"
        }
    },
    avatarView: {
        position: 'absolute',
        top: -28,
        zIndex: 1,
        left: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0 24px",

    },
    avatar: {
        width: 56,
        height: 56,
        border: `solid 2px ${theme.palette.grey[400]}`,
    },
    vs: {
        border: "1px solid ",
        borderRadius: "50%",
        width: "26px",
        height: "26px",
        textAlign: "center",
        position: "relative",
        top: "-90px",
        color: "rgba(255,255,255, 0.7)"
    },
    text: {
        fontSize: 12,
        marginRight: 10,
        color: "#3f3f42",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "6px"
    },
    completed: {
        fontSize: 12,
        color: "#FFFFFF",
        backgroundColor: "#000000",
        display: "inline-flex",
        alignItems: "center",
        marginBottom: "6px",
        padding: "6px 16px",
        borderRadius: "6px"
    },
    live: {
        display: "inline-flex",
        flexWrap: "wrap",
        alignItems: "center",
        fontWeight: "600",
    },
    liveContent: {
        color: "#FFFFFF",
        backgroundColor: "red",
        fontSize: 12,
        padding: "6px 16px",
        borderRadius: "6px",
        maxWidth: "100px",
        marginRight: "auto",
        marginLeft: "auto",

        "& .time": {
            display: "none"
        }
    },
    iconRoot: {
        fontSize: 20,
        marginRight: 10,
        color: "rgba(16, 14, 58, 0.4)"
    },
    winner: {
        color: "#fea621"
    },
}));

export default function GameCard({game, single}) {
    const classes = useStyles();
    const startTime = formatInTimeZone(parseISO(game?.start_time), 'America/New_York', 'hh:mm aa zz')
    const [statusClassName, setStatusClassName] = useState(null);
    const dispatch = useDispatch()
    const {gameLiveData} = useSelector((state) => state.singleGameReducer);
    let converter = require('number-to-words');

    useEffect(() => {
        if(game.played_status === 'COMPLETED') {
            setStatusClassName(classes.completed)
        } else {
            setStatusClassName(classes.text)
        }}, [])

    const liveData = async () => {
        await dispatch(getLiveData(game.api_game_id))
        if(gameLiveData) {
            setStatusClassName("LIVE")
        }
    }

    useEffect(() => {
        if(game.played_status !== 'COMPLETED') {
            liveData()
            setStatusClassName('LIVE')
            setInterval(function(){
                liveData()
                setStatusClassName('LIVE')
            }, 120000);
        }

    }, []);

    useEffect(() => {}, [gameLiveData])

    return(
        <>
            <CmtAdvCard className={classes.singleGame}>
                <ManageSubscription gameSingle="gameSingle" gameId={game.api_game_id} subscriptionStatus={game.subscription_status}/>
                <Box className={classes.contentTop}>
                    <Box className={classes.info}>
                        <CmtSubTitle className="subTitle" content={`${game?.away_team.city} ${game?.away_team.name}`}/>
                        <CmtSubTitle className="subTitle" content={`${game?.home_team.city} ${game?.home_team.name}`}/>
                    </Box>
                </Box>
                <Box className={classes.contentBottom}>
                    <Box className={classes.avatarView}>
                        <Box width="50%" display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <CmtAvatar className={classes.avatar} src={game?.away_team?.official_logo_src ? game?.away_team?.official_logo_src : 'https://via.placeholder.com/350x200'} alt={game?.away_team?.name} />
                            <p style={{marginTop: "6px", fontSize: '13px'}}>{game?.team_total_stats?.away_team?.win} - {game?.team_total_stats?.away_team?.lose}</p>
                            <p style={{marginTop: "6px", fontSize: '12px'}}>{game?.away_team.conference === "Western" ? "WEST" : "EAST"} {converter.toOrdinal(game?.away_team.conference_rank)}</p>
                        </Box>
                        <p className={classes.vs}>vs</p>
                        <Box width="50%" display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <CmtAvatar className={classes.avatar} src={game?.home_team?.official_logo_src ? game?.home_team?.official_logo_src : 'https://via.placeholder.com/350x200'} alt={game?.home_team.name} />
                            <p style={{marginTop: "6px", fontSize: '13px'}}>{game?.team_total_stats?.home_team?.win} - {game?.team_total_stats?.home_team?.lose}</p>
                            <p style={{marginTop: "6px", fontSize: '12px'}}>{game?.home_team.conference === "Western" ? "WEST" : "EAST"} {converter.toOrdinal(game?.home_team.conference_rank)}</p>
                        </Box>
                    </Box>
                    <Box className="content">
                        <Box style={{marginBottom: "24px", fontWeight: 600}}>
                            {
                                single && <Typography className={classes.text}>
                                    {format(parseISO(game.start_time), 'EEEE, MMMM d')}
                                </Typography>
                            }
                        </Box>
                        <Box textAlign={"center"}>
                            <div className={statusClassName}>
                                {statusClassName === "LIVE" &&
                                <>
                                    <div style={{width: "100%", marginBottom: "12px"}} className={classes.liveContent}>LIVE</div>
                                    <div style={{minWidth: "250px", display: "inline-flex", alignItems: "center", justifyContent: "space-between", marginBottom: "12px"}} className={game?.away_score_total > game?.home_score_total && classes.winner}>
                                            <span style={{width: "30%"}}>
                                                {gameLiveData?.awayScoreTotal}
                                                {game?.away_score_total > game?.home_score_total && <><span style={{fontSize: "14px", fontWeight: "600"}}> &lt; </span></>}
                                            </span>
                                        <span className={classes.liveContent} style={{marginLeft: "5px", marginRight: "5px", minWidth: "130px"}}>{gameLiveData?.currentQuarter} Quarter</span>
                                        <span style={{width: "30%"}} className={game?.home_score_total > game?.away_score_total && classes.winner}>
                                                {game?.home_score_total > game?.away_score_total && <>&nbsp; <span style={{fontSize: "14px", fontWeight: "600"}}> &gt; </span> &nbsp;</>}
                                            {gameLiveData?.homeScoreTotal}
                                            </span>
                                    </div>
                                </>
                                }

                                {
                                    game.played_status === "COMPLETED" && game?.away_score_total && game?.home_score_total ?
                                        <>
                                            <span className={game?.away_score_total > game?.home_score_total && classes.winner}>
                                                {game?.away_score_total}

                                                {game?.away_score_total > game?.home_score_total && <><span style={{fontSize: "14px", fontWeight: "600"}}> &lt; </span></>}
                                            </span>
                                            <span style={{marginLeft: "5px", marginRight: "5px"}}>FINAL</span>
                                            <span className={game?.home_score_total > game?.away_score_total && classes.winner}>
                                                {game?.home_score_total > game?.away_score_total && <>&nbsp; <span style={{fontSize: "14px", fontWeight: "600"}}> &gt; </span> &nbsp;</>}
                                                {game?.home_score_total}
                                            </span>
                                        </> :
                                        <>
                                            <div className={statusClassName}>
                                                {
                                                    statusClassName !== "LIVE" &&   <span className="time">
                                                      <AccessTime className={classes.iconRoot}/>
                                                        {startTime}
                                                </span>
                                                }
                                            </div>
                                        </>
                                }
                            </div>
                        </Box>
                        <Box>
                            <Typography  className={classes.text}> <LocationOn className={classes.iconRoot}/>{game?.venue?.name}, {game?.venue?.city}</Typography>
                        </Box>
                    </Box>
                </Box>
            </CmtAdvCard>
        </>
    )
}